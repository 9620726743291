import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { db } from "../../../../firebase/config";
import { ref, update } from "firebase/database";
import SaveSection from "../../components/SaveSection";

const UpdateResults = ({
  toggleSaveButton,
  isSaveButtonActive,
  isDndActivated,
  toggleDnd,
  schedule,
}) => {
  const { tournament, tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();

  const teamsDetails = structuredClone(tournament.teams);
  const groupsDetails = structuredClone(tournament.groups);

  const sortGroup = (group) => {
    const headToHead = (teamAId, teamBId) => {
      let returnValue = 0;

      let counter = 0;

      Object.values(group).forEach((teamId) => {
        if (
          teamsDetails[teamId].wins === teamsDetails[teamAId].wins &&
          teamsDetails[teamId].draws === teamsDetails[teamAId].draws &&
          teamsDetails[teamId].losses === teamsDetails[teamAId].losses
        ) {
          counter++;
        }
      });

      if (counter >= 3) {
        return;
      }

      Object.values(schedule).forEach((day) =>
        day.forEach((match) => {
          if (
            Object.values(match).includes(teamAId) &&
            Object.values(match).includes(teamBId)
          ) {
            if (match.team1Id === teamAId) {
              returnValue = match.team2Score - match.team1Score;
            } else {
              returnValue = match.team1Score - match.team2Score;
            }
          }
        })
      );

      return returnValue;
    };

    const groupSort = (a, b) => {
      return (
        teamsDetails[b].wins - teamsDetails[a].wins ||
        teamsDetails[b].draws - teamsDetails[a].draws ||
        teamsDetails[a].losses - teamsDetails[b].losses ||
        headToHead(a, b) ||
        teamsDetails[b].delta - teamsDetails[a].delta ||
        teamsDetails[b].goals - teamsDetails[a].goals
      );
    };

    return group.sort(groupSort);
  };

  const updateResults = async () => {
    const scheduleReference = ref(db, `tournaments/${tournamentId}/schedule`);
    const teamsReference = ref(db, `tournaments/${tournamentId}/teams/`);
    const groupsReference = ref(db, `tournaments/${tournamentId}/groups/`);

    Object.values(teamsDetails).forEach((team) => {
      team.wins = 0;
      team.losses = 0;
      team.draws = 0;
      team.delta = 0;
      team.goals = 0;
    });

    Object.values(schedule).forEach((day) => {
      day.forEach((match) => {
        if (
          (!match.team1Score && match.team1Score !== 0) ||
          (!match.team2Score && match.team2Score !== 0)
        ) {
          return;
        }

        if (match.team1Score > match.team2Score) {
          if (match.group > Object.keys(tournament.groups).length) {
            teamsDetails[match.team1Id].draws += 1;
          } else {
            teamsDetails[match.team1Id].wins += 1;
            teamsDetails[match.team2Id].losses += 1;
          }
        } else if (match.team2Score > match.team1Score) {
          if (match.group > Object.keys(tournament.groups).length) {
            teamsDetails[match.team2Id].draws += 1;
          } else {
            teamsDetails[match.team2Id].wins += 1;
            teamsDetails[match.team1Id].losses += 1;
          }
        } else {
          teamsDetails[match.team2Id].draws += 1;
          teamsDetails[match.team1Id].draws += 1;
        }

        if (match.group > Object.keys(tournament.groups).length) {
          return;
        }

        teamsDetails[match.team1Id].delta +=
          match.team1Score - match.team2Score;
        teamsDetails[match.team2Id].delta +=
          match.team2Score - match.team1Score;
        teamsDetails[match.team1Id].goals += match.team1Score;
        teamsDetails[match.team2Id].goals += match.team2Score;
      });
    });

    Object.keys(groupsDetails).forEach((groupKey) => {
      sortGroup(groupsDetails[groupKey]);
    });

    try {
      await update(scheduleReference, schedule);
      await update(teamsReference, teamsDetails);
      await update(groupsReference, groupsDetails);
    } catch (error) {
      console.log(error);
    }

    updateTournament("groups", groupsDetails);
    updateTournament("teams", teamsDetails);

    toggleSaveButton(false);

    if (isDndActivated) {
      toggleDnd(false);
    }
  };

  return (
    <SaveSection saveFunction={updateResults} saveButton={isSaveButtonActive} />
  );
};

export default UpdateResults;
