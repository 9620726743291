const generateLowerBracket = (numberOfRounds) => {
  const lowerBracket = {};

  let numberOfGames = 1;

  for (let i = 1; i <= numberOfRounds - 1; i++) {
    lowerBracket[`round${(numberOfRounds - i) * 2 - 1}`] = [];
    lowerBracket[`round${(numberOfRounds - i) * 2}`] = [];
    for (let j = 0; j < numberOfGames; j++) {
      lowerBracket[`round${(numberOfRounds - i) * 2 - 1}`].push({
        team1Id: "tbd",
        team2Id: "tbd",
        team1Score: "",
        team2Score: "",
        winner: "tbd",
      });
      lowerBracket[`round${(numberOfRounds - i) * 2}`].push({
        team1Id: "tbd",
        team2Id: "tbd",
        team1Score: "",
        team2Score: "",
        winner: "tbd",
      });
    }
    numberOfGames = numberOfGames * 2;
  }

  return lowerBracket;
};

export default generateLowerBracket;
