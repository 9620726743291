import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { useState } from "react";
import { db } from "../../../../firebase/config";
import { ref, update } from "firebase/database";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";

const CloseRegistration = ({ details }) => {
  const { tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState(false);

  const toggleConfirmation = () => {
    setConfirmation(!confirmation);
  };

  const writeCloseRegistration = async (event) => {
    event.preventDefault();
    const registrationReference = ref(db, `registration/${tournamentId}`);
    const tournamentReference = ref(db, `tournaments/${tournamentId}/details`);
    const homeReference = ref(db, `home/${tournamentId}`);

    try {
      await update(registrationReference, { isRegistrationOpen: false });
      await update(tournamentReference, { isRegistrationOpen: false });
      await update(homeReference, { isRegistrationOpen: false });
    } catch (error) {
      console.log(error);
    }

    updateTournament("details", {
      ...details,
      isRegistrationOpen: false,
    });

    toggleConfirmation();
  };

  return (
    <div className="mt-4">
      <div onClick={() => toggleConfirmation()}>
        <Button
          i18nPath="page.tournament.details.closeRegistration.close"
          color="red"
          hover={true}
        />
      </div>
      {confirmation && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex justify-center min-w-screen pt-40 px-4 pb-20">
            <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
            <form
              onSubmit={(event) => writeCloseRegistration(event)}
              className="w-11/12 max-w-xl rounded-lg text-left overflow-hidden shadow-xl transform transition-all"
            >
              <div className="bg-white px-8 py-6">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900 mb-4"
                  id="modal-headline"
                >
                  {t("page.tournament.details.closeRegistration.close")}
                </h3>
                <p className="text-sm text-gray-500 whitespace-pre-line">
                  {t("page.tournament.details.closeRegistration.firstLine")}
                </p>
                <p className="text-sm text-gray-500 whitespace-pre-line">
                  {t("page.tournament.details.closeRegistration.secondLine")}
                </p>
              </div>
              <div className="flex justify-end w-full bg-gray-50 px-4 py-3">
                <button
                  type="submit"
                  className="rounded-md border border-transparent shadow-sm px-4 py-2 mr-4 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {t("misc.confirm")}
                </button>
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  {t("misc.cancel")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CloseRegistration;
