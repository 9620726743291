import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ref, onValue } from "firebase/database";
import { db } from "../../firebase/config";

const TournamentContext = React.createContext();
const TournamentUpdateContext = React.createContext();

export const useTournament = () => {
  return useContext(TournamentContext);
};

export const useTournamentUpdate = () => {
  return useContext(TournamentUpdateContext);
};

const TournamentProvider = ({ children }) => {
  const { tournamentId } = useParams();
  const [tournament, setTournament] = useState(false);

  useEffect(() => {
    const tournamentRef = ref(db, `/tournaments/${tournamentId}`);
    const getTournamentInfo = async () => {
      try {
        onValue(
          tournamentRef,
          (snapshot) => {
            const data = snapshot.val();
            setTournament(data);
          },
          { onlyOnce: true }
        );
      } catch (error) {
        console.log(error);
      }
    };

    getTournamentInfo();
  }, [tournamentId]);

  const updateTournament = (route, object) => {
    setTournament((prev) => {
      return { ...prev, [route]: object };
    });
  };

  return (
    <>
      {tournament && (
        <TournamentContext.Provider value={{ tournament, tournamentId }}>
          <TournamentUpdateContext.Provider value={updateTournament}>
            {children}
          </TournamentUpdateContext.Provider>
        </TournamentContext.Provider>
      )}
    </>
  );
};

export default TournamentProvider;
