import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SortableItem from "./SortableItems";
import { useTranslation } from "react-i18next";

const Container = ({ id, items, teams, index, isUserOrganizer }) => {
  const { setNodeRef } = useDroppable({
    id,
  });
  const { t } = useTranslation();

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <div
        ref={setNodeRef}
        className="w-full max-w-xl m-5 border rounded-xl shadow-lg"
      >
        <div
          className="w-full rounded-t-xl bg-indigo-500
          font-bold text-lg text-white text-center py-1"
        >
          {`${t("page.tournament.groups.group")} ${index + 1}`}
        </div>
        <div className="p-5">
          {items.map((id) => (
            <SortableItem
              key={id}
              id={id}
              teams={teams}
              isUserOrganizer={isUserOrganizer}
            />
          ))}
        </div>
      </div>
    </SortableContext>
  );
};

export default Container;
