import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Match from "../component/match/Match";
import { RxDragHandleDots2 } from "react-icons/rx";

export default function ScheduleSortableItem({
  match,
  matchIndex,
  dayIndex,
  updateMatch,
  isUserOrganizer,
  isDndActivated,
  isOnlyMatch,
  isLastMatch,
  isSwiss,
  swapTeams,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: match.matchId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="flex items-center w-full">
      <Match
        key={matchIndex}
        match={match}
        updateMatch={updateMatch}
        isUserOrganizer={isUserOrganizer}
        matchIndex={matchIndex}
        dayIndex={dayIndex}
        isDndActivated={isDndActivated}
        isLastMatch={isLastMatch}
        isSwiss={isSwiss}
        swapTeams={swapTeams}
      />
      {isUserOrganizer && isDndActivated && !isOnlyMatch && (
        <div
          {...attributes}
          {...listeners}
          className="flex justify-center items-center cursor-grab
          border-r border-y border-gray-300 rounded-r-lg h-[50px] bg-gray-200"
        >
          <RxDragHandleDots2 />
        </div>
      )}
    </div>
  );
}
