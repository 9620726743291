import { TbListDetails } from "react-icons/tb";
import { HiRefresh } from "react-icons/hi";
import { MdEditCalendar } from "react-icons/md";
import { IoMdMove } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { BiEdit } from "react-icons/bi";
import { MdOutlinePersonAddAlt } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import IconDefinition from "./icons/IconDefinition";

const About = () => {
  const { t } = useTranslation();
  const iconClassname = "h-6 w-6 mr-2";

  return (
    <div className="flex justify-center mt-8 h-full">
      <div className="flex flex-col justify-between items-center w-11/12 h-full">
        <h1 className="text-4xl font-bold">{t("page.about.title")}</h1>
        <section className="flex flex-col w-full">
          <h2 className="font-bold text-xl">{t("page.about.updates.title")}</h2>
          <div className="ml-2 mb-2">
            <p>{t("page.about.updates.update1")}</p>
          </div>
        </section>
        <section className="flex flex-col w-full">
          <h2 className="font-bold text-xl">{t("page.about.icons.title")}</h2>
          <IconDefinition
            icon={<BiEdit className={iconClassname} />}
            label={t("page.about.icons.editTournamentDetails")}
            definition={t("page.about.icons.editTournamentDetailsText")}
          />
          <IconDefinition
            icon={<MdOutlinePersonAddAlt className={iconClassname} />}
            label={t("page.about.icons.addOrganizer")}
            definition={t("page.about.icons.addOrganizerText")}
          />
          <IconDefinition
            icon={<RiDeleteBinLine className={iconClassname} />}
            label={t("page.about.icons.deleteTournament")}
            definition={t("page.about.icons.deleteTournamentText")}
          />
          <IconDefinition
            icon={<TbListDetails className={iconClassname} />}
            label={t("page.about.icons.teamDetails")}
            definition={t("page.about.icons.teamDetailsText")}
          />
          <IconDefinition
            icon={<HiRefresh className={iconClassname} />}
            label={t("page.about.icons.recreateGroups")}
            definition={t("page.about.icons.recreateGroupsText")}
          />
          <IconDefinition
            icon={<MdEditCalendar className={iconClassname} />}
            label={t("page.about.icons.addDay")}
            definition={t("page.about.icons.addDayText")}
          />
          <IconDefinition
            icon={<IoMdMove className={iconClassname} />}
            label={t("page.about.icons.moveGames")}
            definition={t("page.about.icons.moveGamesText")}
          />
        </section>
        <section className="flex flex-col w-full">
          <h2 className="font-bold text-xl">
            {t("page.about.moreInformation.title")}
          </h2>
          <div className="ml-2 mb-2">
            <h4 className="font-bold">
              {t("page.about.moreInformation.tiebreakers")}
            </h4>
            <p>{t("page.about.moreInformation.tiebreakersText")}</p>
          </div>
          <div className="ml-2 mb-2">
            <h4 className="font-bold">
              {t("page.about.moreInformation.brackets")}
            </h4>
            <p>
              {t("page.about.moreInformation.bracketsText")}
              <a
                href="https://medium.com/the-scoop-bike-polo-news/hosting-a-single-court-tournament-c70e47515169#80aa"
                className="text-blue-500"
              >
                {t("page.about.moreInformation.hayesBracketLink")}
              </a>
              .
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
