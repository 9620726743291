import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export default function SortableItem({ id, teams, isUserOrganizer }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      {isUserOrganizer ? (
        <div
          ref={setNodeRef}
          style={style}
          className="flex justify-center items-center h-[50px] m-2 my-3
          bg-white border border-black rounded-lg cursor-grab"
          {...attributes}
          {...listeners}
        >
          <div>{teams[id]?.teamName}</div>
        </div>
      ) : (
        <div
          className="flex justify-center items-center h-[50px] m-2 my-3
          bg-white border border-black rounded-lg"
        >
          <div>{teams[id]?.teamName}</div>
        </div>
      )}
    </>
  );
}
