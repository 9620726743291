import { useState } from "react";
import { auth } from "../../../firebase/config";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import SignInWithGoogle from "../../../components/google/SignInWithGoogle";
import NotSignedIn from "../../../components/notSignedIn/NotSignedIn";
import { useTranslation } from 'react-i18next';

const SignUp = ({ isLoggedIn }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const signUp = (event) => {
    event.preventDefault();

    createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isLoggedIn) {
    return <NotSignedIn action={"You are already signed in."} />;
  }

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="p-8 rounded shadow-md w-5/6 max-w-md text-center my-8">
        <h2 className="text-2xl font-semibold mb-6">{ t('page.auth.signUp.title') }</h2>
        <form onSubmit={(event) => signUp(event)}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
            { t('page.auth.signUp.form.email') }
            </label>
            <input
              onChange={(event) => setEmail(event.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              placeholder={ t('page.auth.signUp.form.email') }
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              { t('page.auth.signUp.form.password') }
            </label>
            <input
              onChange={(event) => setPassword(event.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              minLength={"6"}
              placeholder={ t('page.auth.signUp.form.password') }
            />
          </div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            { t('page.auth.signUp.form.button') }
          </button>
        </form>
      </div>
      <SignInWithGoogle text={ t('page.auth.signUp.signInWithGoogle') } />
    </div>
  );
};

export default SignUp;
