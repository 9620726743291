import { useTournament } from "../../TournamentContext";
import { useState } from "react";
import { db } from "../../../../firebase/config";
import { ref, update } from "firebase/database";
import { MdOutlinePersonAddAlt } from "react-icons/md";
import { useTranslation } from "react-i18next";

const AddOrganizer = () => {
  const { tournament, tournamentId } = useTournament();
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState(false);
  const [email, setEmail] = useState("");

  const updateOrganizers = async (event) => {
    event.preventDefault();

    const detailsReference = ref(db, `tournaments/${tournamentId}/details/`);
    const homeReference = ref(db, `home/${tournamentId}/`);
    const registrationReference = ref(db, `registration/${tournamentId}/`);

    try {
      await update(detailsReference, {
        organizerEmails: [...tournament.details.organizerEmails, email],
      });
      await update(homeReference, {
        organizerEmails: [...tournament.details.organizerEmails, email],
      });
      await update(registrationReference, {
        organizerEmails: [...tournament.details.organizerEmails, email],
      });
    } catch (error) {
      console.log(error);
    }

    setEmail("");

    setConfirmation(false);
  };

  return (
    <>
      <MdOutlinePersonAddAlt
        onClick={() => setConfirmation(true)}
        className="h-8 w-8 ml-1 hover:fill-green-500"
      />
      {confirmation && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex justify-center min-w-screen pt-40 px-4 pb-20">
            <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
            <form
              onSubmit={(event) => updateOrganizers(event)}
              className="w-11/12 max-w-xl rounded-lg text-left overflow-hidden shadow-xl transform transition-all"
            >
              <div className="bg-white px-8 py-6">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {t("page.tournament.details.addOrganizer.title")}
                </h3>
                <div className="mt-6">
                  <label className="block text-gray-700 text-sm font-semibold mr-3">
                    {t("page.tournament.details.addOrganizer.email")}
                  </label>
                  <input
                    onChange={(event) => setEmail(event.target.value)}
                    className="w-full mt-2 shadow appearance-none border rounded
                    py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="email"
                    required
                  />
                </div>
              </div>
              <div className="flex justify-end w-full bg-gray-50 px-4 py-3">
                <button
                  type="submit"
                  className="rounded-md border border-transparent shadow-sm px-4 py-2 mr-4 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {t("misc.confirm")}
                </button>
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  {t("misc.cancel")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddOrganizer;
