import TournamentCard from "./components/TournamentCard";
import { db } from "../../firebase/config";
import { onValue, ref } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const Home = ({ user }) => {
  const [tournaments, setTournaments] = useState([]);
  const tabsRef = useRef([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const { t } = useTranslation();

  const sortTournaments = (a, b) => {
    return new Date(a.startDate) - new Date(b.startDate);
  };

  const filterTournaments = (tournament) => {
    return (
      tournament.isTournamentPublic ||
      tournament.organizerEmails.includes(user.email)
    );
  };

  const currentDate = new Date(new Date().getTime() - 172800000);
  const upcomingTournaments = [];
  const pastTournaments = [];

  const allTabs = [
    {
      id: "upcoming",
      name: t("page.home.upcoming"),
    },
    {
      id: "previous",
      name: t("page.home.previous"),
    },
  ];

  useEffect(() => {
    const tournamentsRef = ref(db, "/home");
    const getTournaments = async () => {
      try {
        onValue(
          tournamentsRef,
          (snapshot) => {
            const allTournaments = snapshot.val()
              ? Object.values(snapshot.val())
              : [];
            setTournaments(allTournaments);
          },
          { onlyOnce: true }
        );
      } catch (error) {
        console.log(error);
      }
    };

    getTournaments();
  }, []);

  for (const tournament of tournaments) {
    if (new Date(tournament.endDate) >= currentDate) {
      upcomingTournaments.push(tournament);
    } else {
      pastTournaments.push(tournament);
    }
  }

  useEffect(() => {
    if (activeTabIndex === null) {
      return;
    }

    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    };

    setTabPosition();
  }, [activeTabIndex]);

  const renderTournamentCards = (tournaments) => {
    return Object.values(tournaments)
      .filter(filterTournaments)
      .sort(sortTournaments)
      .map((tournament) => (
        <TournamentCard
          key={tournament.id}
          id={tournament.id}
          tournament={tournament}
          isUserOrganizer={tournament.organizerEmails.includes(user.email)}
        />
      ));
  };

  return (
    <div className="flex flex-col w-full justify-center">
      <div className="flew-row relative mx-auto flex h-12 rounded-3xl border border-black/40 backdrop-blur-sm my-4">
        <span
          className="absolute bottom-0 top-0 -z-10 flex overflow-hidden rounded-3xl transition-all duration-300"
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        >
          <span className="h-full w-full rounded-3xl bg-gray-500" />
        </span>
        {allTabs.map((tab, index) => {
          const isActive = activeTabIndex === index;
          return (
            <button
              key={index}
              ref={(el) => (tabsRef.current[index] = el)}
              className={`${
                isActive ? `text-white` : `hover:text-neutral-500`
              } my-auto cursor-pointer select-none rounded-full px-4
               text-center`}
              onClick={() => setActiveTabIndex(index)}
            >
              {tab.name}
            </button>
          );
        })}
      </div>
      <div className="flex justify-center flex-wrap mt-4">
        {activeTabIndex === 0
          ? renderTournamentCards(upcomingTournaments)
          : renderTournamentCards(pastTournaments)}
      </div>
    </div>
  );
};

export default Home;
