import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { ref, set } from "firebase/database";
import { db } from "../../../../firebase/config";
import { useState } from "react";
import { HiRefresh } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import Tip from "../../../../components/Tip";

const CreateGroups = ({ updateItems }) => {
  const { tournament, tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();
  const { t } = useTranslation();

  const [numberOfGroups, setNumberOfGroups] = useState(2);
  const [confirmation, setConfirmation] = useState(false);

  const writeGroups = async () => {
    const groups = {};
    let counter = 1;

    for (const team in tournament?.teams) {
      if (counter === numberOfGroups + 1) {
        counter = 1;
      }
      if (!groups[`group${counter}`]) {
        groups[`group${counter}`] = [];
      }
      groups[`group${counter}`].push(team);
      counter++;
    }

    const reference = ref(db, `tournaments/${tournamentId}/groups/`);
    try {
      await set(reference, groups);
    } catch (error) {
      console.log(error);
    }

    updateItems(groups);

    updateTournament("groups", groups);

    setConfirmation(false);
  };

  return (
    <>
      <div
        onClick={() => {
          setConfirmation(true);
          setNumberOfGroups(2);
        }}
        className="mx-2"
      >
        {!!tournament.groups ? (
          <div
            className="group absolute -top-8 right-0 cursor-pointer"
            onClick={() => setConfirmation(true)}
          >
            <HiRefresh className="h-6 w-6" />
            <div className="absolute bottom-8 left-[-24px] sm:hidden">
              <Tip text={"Recreate Groups"} />
            </div>
          </div>
        ) : (
          <Button
            i18nPath="page.tournament.groups.roundRobin"
            color="blue"
            hover={true}
          />
        )}
      </div>
      {confirmation && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden inline-block align-middle h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 p-6 pb-4">
                <div className="mt-3 mt-0 ml-4 text-left">
                  <h3
                    className="text-lg font-medium text-gray-900 text-left"
                    id="modal-headline"
                  >
                    {!!tournament.groups
                      ? t("page.tournament.groups.createGroups.recreate")
                      : t("page.tournament.groups.createGroups.title")}
                  </h3>
                  <div className="mt-2 justify-start">
                    <p className="text-sm text-gray-500">
                      {t("page.tournament.groups.createGroups.label")}
                    </p>
                    <div className="flex items-center mt-1">
                      <select
                        onChange={(event) =>
                          setNumberOfGroups(Number(event.target.value))
                        }
                        className="shadow border rounded p-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="dropdown"
                        defaultValue={2}
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 px-6 flex justify-end">
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-0 w-auto text-sm"
                >
                  {t("misc.cancel")}
                </button>
                <button
                  onClick={() => writeGroups()}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ml-3 w-auto text-sm"
                >
                  {t("misc.confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateGroups;
