const generateSchedule = (groups) => {
  const schedule = { day1: [] };
  let counter = 0;

  Object.values(groups).forEach((group, index) => {
    if (group.length % 2 === 1) {
      group.push("DUMMY"); // so we can match algorithm for even numbers
    }
    for (let i = 0; i < group.length - 1; i++) {
      for (let j = 0; j < group.length / 2; j++) {
        if (group[j] === "DUMMY" || group[group.length - 1 - j] === "DUMMY")
          continue;
        const match = {
          matchId: `${counter}`,
          team1Id: group[j],
          team1Score: "",
          team2Id: group[group.length - 1 - j],
          team2Score: "",
          group: index + 1,
        };
        schedule.day1.push(match);
        counter++;
      }
      group.splice(1, 0, group[group.length - 1]); // permutate for next round
      group.pop();
    }
  });

  return schedule;
};

export default generateSchedule;
