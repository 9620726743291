const updateHayesBracket = (bracketDetails) => {
  for (let i = 0; i < bracketDetails.hayesUpper.round1.length * 2; i += 2) {
    const matchup = bracketDetails.hayesUpper.round1[Math.round(i / 2)];

    if (matchup.team1Score > matchup.team2Score) {
      matchup["winner"] = matchup.team1Id;
      matchup["loser"] = matchup.team2Id;
    } else if (matchup.team2Score > matchup.team1Score) {
      matchup["winner"] = matchup.team2Id;
      matchup["loser"] = matchup.team1Id;
    } else if (matchup.team1Id === "BYE") {
      matchup["winner"] = matchup.team2Id;
      matchup["loser"] = matchup.team1Id;
    } else if (matchup.team2Id === "BYE") {
      matchup["winner"] = matchup.team1Id;
      matchup["loser"] = matchup.team2Id;
    }
  }

  for (
    let round = 2;
    round <= Object.keys(bracketDetails.hayesUpper).length;
    round++
  ) {
    for (
      let j = 0;
      j < bracketDetails.hayesUpper[`round${round}`].length * 2;
      j += 2
    ) {
      const matchup =
        bracketDetails.hayesUpper[`round${round}`][Math.round(j / 2)];
      matchup.team1Id =
        bracketDetails.hayesUpper[`round${round - 1}`][j].winner;
      matchup.team2Id =
        bracketDetails.hayesUpper[`round${round - 1}`][j + 1].winner;

      if (matchup.team1Score > matchup.team2Score) {
        matchup["winner"] = matchup.team1Id;
        matchup["loser"] = matchup.team2Id;
      } else if (matchup.team2Score > matchup.team1Score) {
        matchup["winner"] = matchup.team2Id;
        matchup["loser"] = matchup.team1Id;
      } else if (matchup.team1Id === "BYE") {
        matchup["winner"] = matchup.team2Id;
        matchup["loser"] = matchup.team1Id;
      } else if (matchup.team2Id === "BYE") {
        matchup["winner"] = matchup.team1Id;
        matchup["loser"] = matchup.team2Id;
      }
    }
  }

  if (
    bracketDetails.hayesUpper[
      `round${Object.keys(bracketDetails.hayesUpper).length}`
    ][0].winner !== "tbd"
  ) {
    const seed = !!bracketDetails.hayesLower
      ? Object.keys(bracketDetails.upper.round1).length - 1
      : 0;

    const teamNumberId = !!bracketDetails.hayesLower ? "team1Id" : "team2Id";

    bracketDetails.upper.round1[seed][teamNumberId] =
      bracketDetails.hayesUpper[
        `round${Object.keys(bracketDetails.hayesUpper).length}`
      ][0].winner;
  }

  if (bracketDetails.hayesLower) {
    for (let i = 0; i < bracketDetails.hayesLower.round1.length * 2; i += 2) {
      const matchup = bracketDetails.hayesLower.round1[Math.round(i / 2)];

      matchup.team1Id = bracketDetails.hayesUpper.round1[i].loser;

      matchup.team2Id = bracketDetails.hayesUpper.round1[i + 1].loser;

      if (matchup.team1Score > matchup.team2Score) {
        matchup["winner"] = matchup.team1Id;
        matchup["loser"] = matchup.team2Id;
      } else if (matchup.team2Score > matchup.team1Score) {
        matchup["winner"] = matchup.team2Id;
        matchup["loser"] = matchup.team1Id;
      } else if (matchup.team1Id === "BYE") {
        matchup["winner"] = matchup.team2Id;
        matchup["loser"] = matchup.team1Id;
      } else if (matchup.team2Id === "BYE") {
        matchup["winner"] = matchup.team1Id;
        matchup["loser"] = matchup.team2Id;
      }
    }

    for (
      let round = 2;
      round <= Object.keys(bracketDetails.hayesLower).length;
      round++
    ) {
      if (round % 2 === 0) {
        for (
          let j = 0;
          j < bracketDetails.hayesLower[`round${round}`].length;
          j++
        ) {
          const matchup = bracketDetails.hayesLower[`round${round}`][j];

          if (round % 4 === 0) {
            matchup.team1Id =
              bracketDetails.hayesUpper[`round${round - (round / 2 - 1)}`][
                j
              ].loser;
          } else {
            matchup.team1Id =
              bracketDetails.hayesUpper[`round${round - (round / 2 - 1)}`][
                bracketDetails.hayesUpper[`round${round - (round / 2 - 1)}`]
                  .length -
                  1 -
                  j
              ].loser;
          }

          matchup.team2Id =
            bracketDetails.hayesLower[`round${round - 1}`][j].winner;

          if (matchup.team1Score > matchup.team2Score) {
            matchup["winner"] = matchup.team1Id;
            matchup["loser"] = matchup.team2Id;
          } else if (matchup.team2Score > matchup.team1Score) {
            matchup["winner"] = matchup.team2Id;
            matchup["loser"] = matchup.team1Id;
          } else if (matchup.team1Id === "BYE") {
            matchup["winner"] = matchup.team2Id;
            matchup["loser"] = matchup.team1Id;
          } else if (matchup.team2Id === "BYE") {
            matchup["winner"] = matchup.team1Id;
            matchup["loser"] = matchup.team2Id;
          }
        }
      } else {
        for (
          let j = 0;
          j < bracketDetails.hayesLower[`round${round}`].length * 2;
          j += 2
        ) {
          const matchup =
            bracketDetails.hayesLower[`round${round}`][Math.round(j / 2)];

          matchup.team1Id =
            bracketDetails.hayesLower[`round${round - 1}`][j].winner;
          matchup.team2Id =
            bracketDetails.hayesLower[`round${round - 1}`][j + 1].winner;

          if (matchup.team1Score > matchup.team2Score) {
            matchup["winner"] = matchup.team1Id;
            matchup["loser"] = matchup.team2Id;
          } else if (matchup.team2Score > matchup.team1Score) {
            matchup["winner"] = matchup.team2Id;
            matchup["loser"] = matchup.team1Id;
          } else if (matchup.team1Id === "BYE") {
            matchup["winner"] = matchup.team2Id;
            matchup["loser"] = matchup.team1Id;
          } else if (matchup.team2Id === "BYE") {
            matchup["winner"] = matchup.team1Id;
            matchup["loser"] = matchup.team2Id;
          }
        }
      }
    }

    if (
      bracketDetails.hayesLower[
        `round${Object.keys(bracketDetails.hayesLower).length}`
      ][0].winner !== "tbd"
    ) {
      bracketDetails.upper.round1[0].team2Id =
        bracketDetails.hayesLower[
          `round${Object.keys(bracketDetails.hayesLower).length}`
        ][0].winner;
    }
  }

  return bracketDetails;
};

export default updateHayesBracket;
