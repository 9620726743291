const sortTeams = (teams, isRandom) => {
  const teamIds = Object.keys(teams);

  const sortTeams = (a, b) => {
    return (
      teams[b].wins - teams[a].wins ||
      teams[b].draws - teams[a].draws ||
      teams[a].losses - teams[b].losses ||
      teams[b].delta - teams[a].delta ||
      teams[b].goals - teams[a].goals
    );
  };

  const randomlySortTeams = (teamIds) => {
    let currentIndex = teamIds.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [teamIds[currentIndex], teamIds[randomIndex]] = [
        teamIds[randomIndex],
        teamIds[currentIndex],
      ];
    }

    return teamIds;
  };

  if (isRandom) {
    return randomlySortTeams(teamIds);
  } else {
    return teamIds.sort(sortTeams);
  }
};

export default sortTeams;
