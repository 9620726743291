import { useTranslation } from "react-i18next";

const Button = ({ i18nPath, color, hover, id }) => {
  const { t } = useTranslation();

  const buttonClassName = `bg-${color}-500 ${
    hover ? `hover:bg-${color}-700` : ""
  } text-white text-lg font-bold py-2 px-4 rounded 
  focus:outline-none focus:shadow-outline`;

  return (
    <button className={buttonClassName} id={id}>
      {t(i18nPath)}
    </button>
  );
};

export default Button;
