import React, { useState } from "react";
import { useTournament } from "../TournamentContext";
import {
  DndContext,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import CreateGroups from "./components/CreateGroups";
import Container from "./Container";
import UpdateGroups from "./components/UpdateGroups";
import MakeGroupsPublic from "./components/MakeGroupsPublic";
import { useTranslation } from "react-i18next";
import CreateSwissRound from "../swiss/GenerateSwissRound";

const Groups = ({ user }) => {
  const { tournament } = useTournament();
  const { t } = useTranslation();

  const [items, setItems] = useState(structuredClone(tournament?.groups));
  const [saveButton, setSaveButton] = useState(false);

  const isUserOrganizer = tournament.details.organizerEmails.includes(
    user.email
  );

  const updateSaveButton = (value) => {
    setSaveButton(value);
  };

  const updateItems = (groups) => {
    setItems(groups);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );

  if (
    (!isUserOrganizer && !tournament.details.isGroupsPublic) ||
    !!tournament.schedule
  ) {
    return;
  }

  if (isUserOrganizer && !!!tournament.groups) {
    return (
      <div className="flex flex-col items-center w-full mb-8">
        <div
          className="w-full text-center font-bold text-2xl
              border-b-2 border-slate-600 mb-4"
        >
          {`${t("page.tournament.groups.selectType")}`}
        </div>
        <div className="flex mt-2">
          {Object.keys(!!tournament.teams ? tournament.teams : { nope: "nope" })
            .length > 3 ? (
            <>
              <CreateGroups updateItems={updateItems} />
              <CreateSwissRound />
            </>
          ) : (
            <p className="text-center">
              {t("page.tournament.groups.minimumTeams")}
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full mb-8">
      <div
        className="w-full text-center font-bold text-2xl
        border-b-2 border-slate-600 mb-2"
      >
        {`${t("page.tournament.groups.groups")} ${
          tournament.details.isGroupsPublic ? "" : t("misc.notPublic")
        }`}
      </div>
      <div className="flex justify-center items-center w-full relative">
        {!tournament.details.isGroupsPublic && (
          <MakeGroupsPublic details={tournament.details} />
        )}
        {isUserOrganizer && <CreateGroups updateItems={updateItems} />}
      </div>
      <div className="flex flex-wrap justify-center mb-2 w-full">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          {items &&
            Object.keys(items).map((group, index) => (
              <Container
                key={group}
                id={group}
                items={items[group]}
                teams={tournament.teams}
                index={index}
                isUserOrganizer={isUserOrganizer}
              />
            ))}
        </DndContext>
      </div>
      {isUserOrganizer && !!!tournament.schedule && (
        <UpdateGroups
          groups={items}
          saveButton={saveButton}
          updateSaveButton={updateSaveButton}
        />
      )}
    </div>
  );

  function findContainer(id) {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect?.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item) => item !== active.id),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = items[activeContainer].indexOf(active.id);
    const overIndex = items[overContainer].indexOf(overId);

    if (activeIndex !== overIndex) {
      setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(
          items[overContainer],
          activeIndex,
          overIndex
        ),
      }));
    }

    updateSaveButton(true);
  }
};

export default Groups;
