import React, { useState } from "react";
import { useTournament } from "../../../TournamentContext";
import { db } from "../../../../../firebase/config";
import { ref, update } from "firebase/database";
import Modal from "../../../../../components/modals/Modal";
import Tip from "../../../../../components/Tip";
import { GiWhistle } from "react-icons/gi";

const Referee = ({ schedule, updateSchedule }) => {
  const { tournament, tournamentId } = useTournament();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const writeReferees = async () => {
    const scheduleReference = ref(db, `tournaments/${tournamentId}/schedule/`);

    try {
      await update(scheduleReference, schedule);
    } catch (error) {
      console.log(error);
    }

    setIsModalVisible(false);
  };

  const updateReferee = (key, value, dayIndex, matchIndex) => {
    updateSchedule({
      ...schedule,
      [`day${dayIndex + 1}`]: [
        ...schedule[`day${dayIndex + 1}`].slice(0, matchIndex),
        Object.assign({}, schedule[`day${dayIndex + 1}`][matchIndex], {
          [key]: value,
        }),
        ...schedule[`day${dayIndex + 1}`].slice(matchIndex + 1),
      ],
    });
  };

  if (!isModalVisible) {
    return (
      <div
        className="flex group h-8 w-8 border-2 border-black rounded-t-sm
        justify-center items-center cursor-pointer relative"
      >
        <GiWhistle
          onClick={() => setIsModalVisible(true)}
          className="h-6 w-6"
        />
        <div className="absolute bottom-8 sm:hidden">
          <Tip text={"Referees"} />
        </div>
      </div>
    );
  }

  return (
    <>
      {isModalVisible && (
        <Modal
          i18nTitle={"page.tournament.schedule.referee.title"}
          i18nSubTitle={"page.tournament.schedule.referee.subtitle"}
          ModalBody={
            <div className="flex flex-col w-full max-h-72 overflow-y-auto items-center">
              {Object.keys(schedule).map((day, dayIndex) => (
                <div key={day}>
                  <h3 className="font-bold">{`Day ${dayIndex + 1}`}</h3>
                  {Object.values(schedule[day]).map((match, matchIndex) => {
                    return (
                      <div key={`${dayIndex}${matchIndex}`}>
                        <div className="flex">
                          <p> {tournament.teams[match.team1Id].teamName}</p>
                          <p className="mx-2">v</p>
                          <p> {tournament.teams[match.team2Id].teamName}</p>
                        </div>
                        <input
                          value={match.referee || ""}
                          className="border-2"
                          onChange={(event) =>
                            updateReferee(
                              "referee",
                              event.target.value,
                              dayIndex,
                              matchIndex
                            )
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          }
          onCancel={onCancel}
          onConfirm={writeReferees}
        />
      )}
    </>
  );
};

export default Referee;
