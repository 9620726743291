import React from "react";
import { useTranslation } from "react-i18next";

const SaveSection = ({ saveFunction, saveButton }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`z-50 fixed duration-500 ${
        saveButton ? "sm:top-[262px] top-28" : "-top-20"
      }`}
    >
      <div
        onClick={() => saveFunction()}
        className="bg-black py-2 px-4 rounded-2xl cursor-pointer
        hover:text-gray-200 text-lg text-white font-bold shadow-xl 
        focus:outline-none focus:shadow-outline"
      >
        {t("page.tournament.components.saveSection.saveChanges")}
      </div>
    </div>
  );
};

export default SaveSection;
