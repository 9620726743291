import { useState } from "react";
import { db } from "../../firebase/config";
import { ref as ref_database, push, set, update } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { storage } from "../../firebase/config";
import {
  ref as ref_storage,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { useTranslation } from "react-i18next";
import NotSignedIn from "../../components/notSignedIn/NotSignedIn";
import Button from "../../components/Button";

const Organize = ({ user }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [details, setDetails] = useState({
    isTournamentPublic: false,
    isTeamsPublic: false,
    isSchedulePublic: false,
    isBracketPublic: false,
    isGameTimesVisible: false,
    isRegistrationOpen: false,
    startTimes: ["9:00"],
    gameLength: 12,
    interval: 5,
    numberOfCourts: 1,
  });
  const [image, setImage] = useState({});

  const writeTournament = async (event) => {
    event.preventDefault();

    const button = document.getElementById("organize-submit-button");

    button.disabled = true;

    const tournamentsReference = ref_database(db, "tournaments/");
    const homeReference = ref_database(db, "home/");
    const registrationReference = ref_database(db, "registration/");

    const addedItem = await push(tournamentsReference);

    if (image.name) {
      const imageName = Date.now().toString() + image.name;
      const imageRef = ref_storage(storage, `images/${imageName}`);
      uploadBytes(imageRef, image)
        .then((snapshot) => {
          return getDownloadURL(snapshot.ref);
        })
        .then((downloadURL) => {
          set(addedItem, {
            details: {
              ...details,
              imageSrc: downloadURL,
              imageFileName: imageName,
              organizerEmails: ["shillier11@gmail.com", user.email],
            },
          });

          update(homeReference, {
            [addedItem.key]: {
              id: addedItem.key,
              organizerEmails: ["shillier11@gmail.com", user.email],
              name: details.name,
              location: details.location,
              startDate: details.startDate,
              endDate: details.endDate,
              isRegistrationOpen: false,
              isTournamentPublic: false,
              imageSrc: downloadURL,
            },
          });

          update(registrationReference, {
            [addedItem.key]: {
              organizerEmails: ["shillier11@gmail.com", user.email],
              isRegistrationOpen: false,
              playersPerTeam: 3,
            },
          });
        })
        .then(() => {
          navigate(`/${addedItem.key}`);
        })
        .catch((error) => console.log(error));
    } else {
      try {
        await set(addedItem, {
          details: {
            ...details,
            organizerEmails: ["shillier11@gmail.com", user.email],
          },
        });

        await update(homeReference, {
          [addedItem.key]: {
            id: addedItem.key,
            organizerEmails: ["shillier11@gmail.com", user.email],
            name: details.name,
            location: details.location,
            startDate: details.startDate,
            endDate: details.endDate,
            isRegistrationOpen: false,
            isTournamentPublic: false,
          },
        });

        await update(registrationReference, {
          [addedItem.key]: {
            organizerEmails: ["shillier11@gmail.com", user.email],
            isRegistrationOpen: false,
          },
        });
        navigate(`/${addedItem.key}`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (!!!user) {
    return (
      <NotSignedIn action={"You must be signed in to create a tournament."} />
    );
  } else {
    return (
      <div className="flex flex-col justify-center items-center">
        <div className="p-8 rounded shadow-md w-5/6 max-w-2xl text-center my-8">
          <h2 className="text-2xl font-semibold mb-6">
            {t("page.organize.title")}
          </h2>
          <form onSubmit={(event) => writeTournament(event)}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("page.organize.newTournament.form.name")}
              </label>
              <input
                onChange={(event) =>
                  setDetails((prev) => {
                    return { ...prev, name: event.target.value };
                  })
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("page.organize.newTournament.form.location")}
              </label>
              <input
                onChange={(event) =>
                  setDetails((prev) => {
                    return { ...prev, location: event.target.value };
                  })
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                required
              />
            </div>
            <div className="flex flex-wrap justify-around w-full">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("page.organize.newTournament.form.startDate")}
                </label>
                <input
                  placeholder="Start Date"
                  type={"date"}
                  onChange={(event) => {
                    setDetails((prev) => {
                      return {
                        ...prev,
                        startDate: new Date(event.target.value).toDateString(),
                      };
                    });
                  }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-center"
                  // min={new Date().toISOString().split("T")[0]}
                  max={
                    details.endDate
                      ? new Date(details.endDate).toISOString().split("T")[0]
                      : null
                  }
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {t("page.organize.newTournament.form.endDate")}
                </label>
                <input
                  placeholder="End Date"
                  type={"date"}
                  onChange={(event) => {
                    setDetails((prev) => {
                      return {
                        ...prev,
                        endDate: new Date(event.target.value).toDateString(),
                      };
                    });
                  }}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-center"
                  min={
                    details.startDate
                      ? new Date(details.startDate).toISOString().split("T")[0]
                      : new Date().toISOString().split("T")[0]
                  }
                  required
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("page.organize.newTournament.form.description")}
              </label>
              <textarea
                onChange={(event) =>
                  setDetails((prev) => {
                    return { ...prev, description: event.target.value };
                  })
                }
                className="shadow appearance-none border rounded w-full min-h-[250px]
                 py-2 pb-8 px-3 text-gray-700 leading-tight
                 focus:outline-none focus:shadow-outline"
                type="text"
                required
              />
            </div>
            <div className="flex justify-start items-center my-4">
              <label
                htmlFor="image-upload"
                className="cursor-pointer bg-blue-50 text-blue-700 rounded-full text-md
                py-2 px-6 mr-5 font-medium"
              >
                {t("page.organize.newTournament.form.chooseImage")}
              </label>
              <input
                id="image-upload"
                type="file"
                onChange={(event) => setImage(event.target.files[0])}
                accept="image/png, image/jpeg"
                className="file:hidden"
              />
            </div>
            <Button
              i18nPath="page.organize.newTournament.form.create"
              color="blue"
              hover={true}
              id="organize-submit-button"
            />
          </form>
        </div>
      </div>
    );
  }
};

export default Organize;
