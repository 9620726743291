import React from "react";
import { useTranslation } from "react-i18next";

const ModalFooter = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-end bg-gray-50 py-3 px-6 border-t">
      <button
        onClick={() => onCancel(false)}
        type="button"
        className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-0 w-auto text-sm"
      >
        {t("misc.cancel")}
      </button>
      <button
        onClick={() => onConfirm()}
        type="button"
        className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ml-3 w-auto text-sm"
      >
        {t("misc.confirm")}
      </button>
    </div>
  );
};

export default ModalFooter;
