import { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Sidebar from "./Sidebar";
import { useTranslation } from "react-i18next";

const Navbar = ({ isLoggedIn }) => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [rotation, setRotation] = useState(0);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    setRotation(rotation === 0 ? 90 : 0);
  };

  return (
    <>
      <nav className="fixed top-0 left-0 w-screen bg-white z-50 h-20 border-b">
        <div className="w-full h-full flex justify-between items-center px-6">
          <FaBars
            onClick={() => toggleSidebar()}
            style={{ transform: `rotate(${rotation}deg)` }}
            className="h-8 w-8 cursor-pointer duration-500"
          />
          <Link to={"/"}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/shuffler-5e5dd.appspot.com/o/assets%2FShuffler_Logo-removebg-preview.png?alt=media&token=5e70709d-c0f9-4fc3-b3b6-e739c9b6ea75"
              className="w-12"
              alt="navbar-logo"
            />
          </Link>
          {isLoggedIn ? (
            <div className="w-8" />
          ) : (
            <Link
              to={"/auth"}
              className="bg-blue-500 hover:bg-blue-700
              text-white font-bold py-2 px-4 rounded"
            >
              {t("component.navbar.signIn")}
            </Link>
          )}
        </div>
      </nav>
      <Sidebar
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        isLoggedIn={isLoggedIn}
      />
    </>
  );
};

export default Navbar;
