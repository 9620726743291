export const fr = {
  misc: {
    confirm: "Confirmer",
    cancel: "Annuler",
    yes: "Oui",
    no: "Non",
    notPublic: "(Privé)",
    save: "Sauvegarder",
    team: "Équipe",
  },
  component: {
    navbar: {
      signIn: "Connexion",
    },
    sidebar: {
      pagelink: {
        home: "Tous les tournois",
        organize: "Créer un tournoi",
        planner: "Planificateur",
        about: "À propos",
      },
    },
    tournamentCard: {
      registrationsOpen: "Inscriptions ouvertes",
    },
  },
  page: {
    home: {
      upcoming: "Avenir",
      previous: "Ancien",
    },
    auth: {
      signIn: {
        title: "Connexion",
        form: {
          email: "Email",
          password: "Mot de passe",
          button: "Connexion",
        },
        signInWithGoogle: "Connexion avec Google",
        signUp: {
          text: "No account yet? ",
          link: "Sign Up",
        },
      },
      signUp: {
        title: "Créer un compte",
        form: {
          email: "Email",
          password: "Mot de passe",
          button: "Créer mon compte",
        },
        signInWithGoogle: "Connexion avec Google",
      },
    },
    about: {
      title: "À propos",
      updates: {
        title: "Mises à jour",
        update1: "Swiss Rounds sont en maintenance.",
      },
      icons: {
        title: "Icônes",
        editTournamentDetails: "Modifier les détails du tournoi",
        editTournamentDetailsText:
          "Permet de modifier le nom, la date, le lieu et la description du tournoi.",
        addOrganizer: "Ajouter un organisateur/organisatrice",
        addOrganizerText:
          "Entrez l'adresse e-mail d'un autre utilisateur pour leur donner toutes les autorisations pour un tournoi.",
        deleteTournament: "Supprimer le tournoi",
        deleteTournamentText:
          "Supprimer le tournoi. Cela va supprimer toutes les données associées.",
        teamDetails: "Détails des équipes",
        teamDetailsText:
          "Redirige vers la page de détails des équipes pour afficher plus d'informations sur chaque équipe et apporter des modifications aux équipes et aux joueurs.",
        recreateGroups: "Recréer les groupes",
        recreateGroupsText:
          "Modifiez le nombre de groupes ou mettez à jour les groupes si des équipes ont été ajoutées ou supprimées.",
        addDay: "Ajouter un jour",
        addDayText:
          "Sélectionnez le nombre de jours nécessaires pour exécuter la planification. Cela réinitialisera toutes les modifications que vous avez déjà apportées.",
        moveGames: "Déplacer les jeux",
        moveGamesText: "Déplacez les jeux et personnalisez le calendrier.",
      },
      moreInformation: {
        title: "Plus d'information",
        tiebreakers: "Bris d'égalité",
        tiebreakersText:
          "Les équipes de chaque groupe sont classées par victoires, nuls, défaites, face-à-face, delta et buts pour dans cet ordre. La fonction face-à-face ne fonctionne que si deux équipes ont le même nombre de victoires, de nuls et de défaites. S'il y a trois équipes ou plus avec les mêmes résultats, il les classera en fonction du Delta.",
        brackets: "Brackets",
        bracketsText:
          "ShufflePolo gère les brackets Single, Double, Single Hayes et Double Hayes. Les brackets Hayes sont un système conçu pour que les équipes les moins bien classées s'affrontent pour avoir une chance de se qualifier pour la bracket supérieure. Plus d'informations sur ce type de bracket peuvent être trouvées ",
        hayesBracketLink: "ici",
      },
    },
    clubs: {
      new: "Nouveau Club",
    },
    organize: {
      title: "Créer un tournoi",
      newTournament: {
        form: {
          name: "Nom",
          location: "Lieu",
          startDate: "Date de début",
          endDate: "Date de fin",
          description: "Description",
          chooseImage: "Choisir une image",
          create: "Créer",
        },
      },
    },
    planner: {
      title: "Planificateur",
      subTitle: "Calculateur pour trouver la taille de tournoi optimale",
      form: {
        teamAmount: "Nombre d'équipes",
        gameLength: "Durée d'un match",
        gameInterval: "Intervalle entre les matchs",
        groupAmount: "Nombre de groupes",
        courtAmount: "Nombre de terrains",
      },
      result: {
        titles: {
          courtTime: "Temps de jeu (heures)",
          gameFrequency: "Fréquence des matchs (heures)",
        },
        legend: {
          tooMuch: "Beaucoup de temps libre",
          smooth: "Se déroulera sans problème",
          flush: "C'est juste",
          notEnough: "Impossible sans lumière artificielle",
        },
        table: {
          teamAmount: "Équipes",
          gameAmount: "Matchs",
          gameDuration: "{{gameLength}} min",
        },
      },
    },
    register: {
      mustSignIn: "Désolé, vous devez être connecté pour vous inscrire.",
      closed: "Désolé, les inscriptions sont fermées",
      success: "Félicitations, votre inscription a été un succès!",
      form: {
        teamName: "Nom de l'équipe",
        email: "Email",
        player: "Joueur/Joueuse",
        homeClub: "Club",
        otherClub: "Autre",
        housingInfo: "Informations sur le logement",
        additionalInfo: "Veuillez fournir des détails supplémentaires",
        needHousing: "Besoin de logement",
        button: "S'inscrire",
      },
    },
    tournament: {
      components: {
        saveSection: {
          saveChanges: "Sauvegarder",
        },
      },
      details: {
        register: "Inscription",
        closeRegistration: {
          close: "Fermer l'inscription",
          firstLine: "Les équipes ne pourront plus s'inscrire.",
          secondLine:
            "En tant qu'organisateur, vous pourrez toujours ajouter et supprimer des équipes.",
        },
        openRegistration: {
          open: "Ouvrir l'inscription",
          numberOfPlayers: "Nombre minimum de joueurs par équipe",
          requireEmail: "L'adresse email des équipes inscrites est obligatoire",
          requestHousing: "Les équipes peuvent demander à être logées",
          requestHousingDetails:
            "Les équipes peuvent ajouter des détails à leur demande de logement",
        },
        deleteTournament: {
          delete: "Supprimer le tournoi",
          firstLine:
            "Toutes les données de ce tournoi seront définitivement supprimées.",
          secondLine: "Cette action est irréversible.",
        },
        addOrganizer: {
          title: "Ajouter un organisateur",
          email: "Entrer l'email",
        },
        makePublic: {
          title: "Publier le tournoi",
          text: "Cette action est irréversible.",
        },
      },
      teams: {
        teams: "Équipes",
        makePublic: {
          title: "Publier la liste des équipes",
          firstLine: "Êtes-vous sûr de rendre la liste des équipes publique ?",
          secondLine: "Cette action est irréversible.",
        },
        addMockTeams: {
          title: "Ajouter des équipes fictives",
          label: "Nombre d'équipes fictives à ajouter:",
        },
      },
      waitlist: {
        waitlist: "Liste d'attente",
      },
      groups: {
        selectType: "Choisir le type de phase de groupes",
        roundRobin: "Round Robin",
        group: "Groupe",
        groups: "Groupes",
        minimumTeams:
          "Il doit y avoir au moins 4 équipes inscrites pour sélectionner.",
        createGroups: {
          title: "Créer des groupes",
          recreate: "Recréer les groupes",
          label:
            "Choisir le nombre de groupes dans lesquels répartir les équipes:",
        },
        makePublic: {
          title: "Publier les groupes",
          firstLine:
            "Êtes-vous sûr de vouloir rendre la liste de groupes publique ?",
          secondLine: "Cette action est irréversible.",
        },
      },
      swiss: {
        swissRounds: "Swiss Rounds",
        round: "Round",
        generate: {
          title: "Générer le prochain round",
          label:
            "Les équipes seront classées et joindre pour créer les matchs.",
        },
        create: {
          title: "Créer le swiss round",
          label:
            "Les équipes seront mélangées et le premier tour de matchs sera généré. Si la liste des équipes est inégale, un saut de tour (bye) sera ajouté.",
        },
      },
      schedule: {
        schedule: "Programme",
        court: "Terrain",
        day: "Jour",
        days: {
          title: "Nombre de Jours",
          label:
            "Choisir le nombre de jours qu'il faudra pour jouer tous les matchs hors séries éliminatoires.",
        },
        create: {
          title: "Créer le programme",
          firstLine:
            "Cela empêchera toute modification des groupes et des équipes sauf si le programme est supprimé. Les équipes sur la liste d'attente seront supprimées.",
          secondLine: "Êtes-vous sûr de vouloir effectuer cette action ?",
        },
        delete: {
          title: "Supprimer le programme",
          firstLine: "Êtes-vous sûr de vouloir effectuer cette action ?",
        },
        public: {
          title: "Publier le programme et les classements",
          firstLine:
            "Êtes-vous sûr de vouloir rendre le planning et les classements publiques ?",
          secondLine: "Cette action est irréversible.",
        },
        gameTimes: {
          title: "Horaire des jeux",
          subtitle: "Heure de début estimée pour chaque match",
          start: "Heure de début",
          length: "Durée du jeu",
          interval: "Intervalle",
          courts: "Terrains",
          save: "Sauvegarder",
        },
        tiebreaker: {
          title: "Match décisif",
          subtitle: "Ajouter un match décisif",
          detail:
            "Le gagnant se verra attribuer un tirage au sort qui le placera devant le perdant au classement",
        },
        referee: {
          title: "Arbitre",
          subtitle: "Insérer un arbitre pour n'importe quel match",
        },
      },
      standings: {
        title: "Classements",
        team: "Équipe",
      },
      bracket: {
        title: "Bracket",
        create: {
          title: "Créer le bracket",
          warning:
            "Vous ne pourrez plus apporter des modifications pour le programme.",
          type: "Type de bracket:",
          single: "Simple",
          double: "Double",
          hayesSingle: "Hayes Simple",
          hayesDouble: "Hayes Double",
          numberOfPlayoffTeams: "Nombre d'équipes en séries éliminatoires:",
          numberOfUpperBracketTeams: "Nombre d'équipes en bracket finale:",
        },
        delete: {
          title: "Supprimer le bracket",
          firstLine: "Vous pourrez recréer le bracket dans le future.",
          secondLine: "Êtes-vous sûr de vouloir effectuer cette action ?",
        },
        public: {
          title: "Publier le bracket",
          firstLine: "Êtes-vous sûr de vouloir continuer ?",
        },
      },
      registrants: {
        teamName: "Nom de l'équipe",
        players: "Joueurs/Joeuses",
        email: "Email",
        needsHousing: "Besoin de logement",
        housingDetails: "Détails du logement",
      },
    },
  },
};
