import React from "react";
import TournamentProvider from "./TournamentContext";
import Teams from "./teams/Teams";
import Groups from "./groups/Groups";
import Bracket from "./bracket/Bracket";
import Schedule from "./schedule/Schedule";
import Standings from "./standings/Standings";
import Details from "./details/Details";

const Tournament = ({ user }) => {
  return (
    <TournamentProvider>
      <div className="flex flex-col items-center mt-4">
        <div className="flex flex-col justify-center w-11/12">
          <Details user={user} />
          <Bracket user={user} />
          <Standings user={user} />
          <Schedule user={user} />
          <Groups user={user} />
          <Teams user={user} />
        </div>
      </div>
    </TournamentProvider>
  );
};

export default Tournament;
