import React from "react";
import Up from "./components/Up";
import Down from "./components/Down";
import Straight from "./components/Straight";
import DashedStraight from "./components/DashedStraight";

const PathLines = ({ bracketLevel, roundNumber, matchPosition, bracket }) => {
  const svgHeight =
    bracketLevel === "upper" || bracketLevel === "hayesUpper"
      ? 88 * Math.pow(2, roundNumber - 2)
      : 88 * Math.pow(2, Math.round(roundNumber / 2) - 2);

  const previousGameExists = (gameNumber, isStraight) => {
    const previousMatch = isStraight
      ? bracket[bracketLevel][`round${roundNumber - 1}`][matchPosition]
      : bracket[bracketLevel][`round${roundNumber - 1}`][
          matchPosition * 2 + gameNumber - 1
        ];

    if (previousMatch?.team1Id === "BYE" || previousMatch?.team2Id === "BYE") {
      return false;
    }

    return true;
  };

  if (roundNumber === 1) {
    return;
  }

  if (
    bracketLevel === "upper" &&
    !!bracket.lower &&
    (roundNumber === Object.keys(bracket.upper).length ||
      roundNumber === Object.keys(bracket.upper).length - 1)
  ) {
    if (roundNumber === Object.keys(bracket.upper).length) {
      return <DashedStraight svgHeight={svgHeight} />;
    }
    return <Straight svgHeight={svgHeight / 4} />;
  }

  if (
    (bracketLevel === "lower" && roundNumber % 2 === 0) ||
    (bracketLevel === "hayesLower" && roundNumber % 2 === 0)
  ) {
    return (
      <>{previousGameExists(1, true) && <Straight svgHeight={svgHeight} />}</>
    );
  }

  return (
    <div className="flex items-center w-[20px]">
      {previousGameExists(1, false) && <Up svgHeight={svgHeight} />}
      {previousGameExists(2, false) && <Down svgHeight={svgHeight} />}
    </div>
  );
};

export default PathLines;
