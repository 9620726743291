import { useTournament, useTournamentUpdate } from "../TournamentContext";
import React, { useState } from "react";
import {
  DndContext,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { db } from "../../../firebase/config";
import { ref, update } from "firebase/database";
import CreateSchedule from "./component/CreateSchedule";
import UpdateResults from "./component/UpdateResults";
import MakeSchedulePublic from "./component/MakeSchedulePublic";
import ScheduleContainer from "./DND/ScheduleContainer";
import MatchTimes from "./component/matchTimes/MatchTimes";
import DeleteSchedule from "./component/DeleteSchedule";
import { IoMdMove } from "react-icons/io";
import { useTranslation } from "react-i18next";
import AddDay from "./component/AddDay";
import GenerateSwissRound from "../swiss/GenerateSwissRound";
import Tip from "../../../components/Tip";
import TiebreakerMatch from "./component/TiebreakerMatch";
import Referee from "./component/referee/Referee";

const Schedule = ({ user }) => {
  const { tournament, tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();
  const { t } = useTranslation();

  const [schedule, setSchedule] = useState(tournament.schedule);
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
  const [isDndActivated, setDndActivated] = useState(false);

  const details = tournament.details;
  const isUserOrganizer = details.organizerEmails.includes(user.email);

  const toggleSaveButton = (value) => {
    setIsSaveButtonActive(value);
  };

  const updateSchedule = (value) => {
    setSchedule(value);
  };

  const toggleDnd = (value) => {
    setDndActivated(value);
  };

  const updateMatch = (key, value, dayIndex, matchIndex) => {
    toggleSaveButton(true);

    setSchedule({
      ...schedule,
      [`day${dayIndex + 1}`]: [
        ...schedule[`day${dayIndex + 1}`].slice(0, matchIndex),
        Object.assign({}, schedule[`day${dayIndex + 1}`][matchIndex], {
          [key]: value,
        }),
        ...schedule[`day${dayIndex + 1}`].slice(matchIndex + 1),
      ],
    });
  };

  const deleteSwissRound = async (dayIndex) => {
    const fakeSchedule = structuredClone(schedule);
    delete fakeSchedule[`day${dayIndex + 1}`];

    const scheduleReference = ref(db, `tournaments/${tournamentId}/schedule/`);

    try {
      await update(scheduleReference, { [`day${dayIndex + 1}`]: null });
    } catch (error) {
      console.log(error);
    }

    updateTournament("schedule", fakeSchedule);

    setSchedule(fakeSchedule);
  };

  const swapTeams = async (dayIndex, matchIndex) => {
    const fakeSchedule = structuredClone(schedule);
    const firstTeamId = schedule[`day${dayIndex + 1}`][matchIndex].team1Id;
    const secondTeamId = schedule[`day${dayIndex + 1}`][matchIndex + 1].team1Id;

    fakeSchedule[`day${dayIndex + 1}`][matchIndex].team1Id = secondTeamId;
    fakeSchedule[`day${dayIndex + 1}`][matchIndex + 1].team1Id = firstTeamId;

    const scheduleReference = ref(db, `tournaments/${tournamentId}/schedule/`);

    try {
      await update(scheduleReference, fakeSchedule);
    } catch (error) {
      console.log(error);
    }

    setSchedule(fakeSchedule);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor)
  );

  if ((!isUserOrganizer && !details.isSchedulePublic) || !!!tournament.groups) {
    return;
  }

  if (isUserOrganizer && !!!schedule) {
    return (
      <div className="flex flex-col items-center w-full mb-8">
        <div
          className="w-full text-center font-bold text-2xl
          border-b-2 border-slate-600 mb-4"
        >
          {`${t("page.tournament.schedule.schedule")} ${t("misc.notPublic")}`}
        </div>
        <CreateSchedule updateSchedule={updateSchedule} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center mb-8 mt-2">
      <div className="w-full text-center border-b-2 border-slate-600 mb-3 relative">
        <p className="font-bold text-2xl">{`${t(
          "page.tournament.schedule.schedule"
        )} ${details.isSchedulePublic ? "" : t("misc.notPublic")}`}</p>
        {isUserOrganizer && !!!tournament.bracket && (
          <DeleteSchedule
            tournament={tournament}
            updateTournament={updateTournament}
            tournamentId={tournamentId}
          />
        )}
      </div>
      {isUserOrganizer && (
        <>
          {!details.isSchedulePublic && <MakeSchedulePublic />}
          <div className="flex justify-between w-1/2 max-w-xs mt-2">
            <MatchTimes />
            <AddDay
              schedule={structuredClone(schedule)}
              tournamentId={tournamentId}
              toggleDnd={toggleDnd}
              isSwiss={details.isSwiss}
            />
            <div
              className="flex group h-8 w-8 border-2 border-black rounded-t-sm
              justify-center items-center cursor-pointer relative"
            >
              <IoMdMove
                onClick={() => toggleDnd(!isDndActivated)}
                className={`h-6 w-6 fill-black ${
                  isDndActivated
                    ? "fill-red-500 border-t-red-500 border-x-red-500 border-b-black"
                    : "fill-black border-black"
                }`}
              />
              <div className="absolute bottom-8 sm:hidden">
                <Tip text={"Move Games"} />
              </div>
            </div>
            <Referee schedule={schedule} updateSchedule={updateSchedule} />
            <TiebreakerMatch
              schedule={schedule}
              updateSchedule={updateSchedule}
            />
          </div>
        </>
      )}
      <div className="w-full flex flex-wrap justify-center mt-4">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          {Object.keys(schedule).map((day, dayIndex) => (
            <ScheduleContainer
              key={day}
              day={day}
              matches={schedule[day]}
              dayIndex={dayIndex}
              updateMatch={updateMatch}
              isUserOrganizer={isUserOrganizer}
              isDndActivated={isDndActivated}
              isSwiss={tournament.details.isSwiss}
              isLastDay={dayIndex + 1 === Object.keys(schedule).length}
              deleteSwissRound={deleteSwissRound}
              swapTeams={swapTeams}
            />
          ))}
        </DndContext>
      </div>
      {isUserOrganizer && (
        <>
          {tournament.details.isSwiss && (
            <GenerateSwissRound updateSchedule={updateSchedule} />
          )}
          {!!!tournament.bracket && (
            <UpdateResults
              schedule={schedule}
              isSaveButtonActive={isSaveButtonActive}
              toggleSaveButton={toggleSaveButton}
              isDndActivated={isDndActivated}
              toggleDnd={toggleDnd}
            />
          )}
        </>
      )}
    </div>
  );

  function findContainer(id) {
    if (id in schedule) {
      return id;
    }

    const findContainer = (id) => {
      for (const [containerId, matches] of Object.entries(schedule)) {
        if (matches.some((match) => match.matchId === id)) {
          return containerId;
        }
      }
      return null;
    };

    return findContainer(id);
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setSchedule((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex(
        (match) => match.matchId === active.id
      );
      const overIndex = overItems.findIndex(
        (match) => match.matchId === overId
      );

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect?.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter(
            (match) => match.matchId !== active.id
          ),
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          schedule[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    const activeIndex = schedule[activeContainer].findIndex(
      (match) => match.matchId === active.id
    );
    const overIndex = schedule[overContainer].findIndex(
      (match) => match.matchId === overId
    );

    if (activeIndex !== overIndex) {
      setSchedule((schedule) => ({
        ...schedule,
        [overContainer]: arrayMove(
          schedule[overContainer],
          activeIndex,
          overIndex
        ),
      }));
    }

    toggleSaveButton(true);
  }
};

export default Schedule;
