import React from "react";

const Straight = ({ svgHeight }) => {
  return (
    <svg width="20" height={`${svgHeight / 4}`}>
      <path
        stroke="black"
        strokeWidth="2"
        fill="none"
        d={`M 0 ${svgHeight / 8} L 20 ${svgHeight / 8}`}
      />
    </svg>
  );
};

export default Straight;
