import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { db } from "../../../../firebase/config";
import { ref, update } from "firebase/database";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const MakeGroupsPublic = ({ details }) => {
  const { tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState(false);

  const updateVisibility = async () => {
    const reference = ref(db, `tournaments/${tournamentId}/details/`);

    try {
      await update(reference, {
        isGroupsPublic: true,
      });
    } catch (error) {
      console.log(error);
    }

    updateTournament("details", {
      ...details,
      isGroupsPublic: true,
    });

    setConfirmation(false);
  };

  return (
    <>
      <div
        onClick={() => setConfirmation(true)}
        className="text-red-500 font-semibold p-2 my-2 cursor-pointer
          border border-red-600 rounded-md"
      >
        {t("page.tournament.groups.makePublic.title")}
      </div>
      {confirmation && (
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden inline-block align-middle h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 p-6 pb-4">
                <div className="mt-3 ml-4 text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    {t("page.tournament.groups.makePublic.title")}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 whitespace-pre-line">
                      {t("page.tournament.groups.makePublic.firstLine")}
                    </p>
                    <p className="text-sm text-gray-500 whitespace-pre-line">
                      {t("page.tournament.groups.makePublic.secondLine")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 px-6 flex justify-end">
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-0 w-auto text-sm"
                >
                  {t("misc.cancel")}
                </button>
                <button
                  onClick={() => updateVisibility()}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ml-3 w-auto text-sm"
                >
                  {t("misc.confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MakeGroupsPublic;
