const updateUpperBracket = (bracketDetails) => {
  for (let i = 0; i < bracketDetails.upper.round1.length * 2; i += 2) {
    const matchup = bracketDetails.upper.round1[Math.round(i / 2)];

    if (matchup.team1Score > matchup.team2Score) {
      matchup["winner"] = matchup.team1Id;
      matchup["loser"] = matchup.team2Id;
    } else if (matchup.team2Score > matchup.team1Score) {
      matchup["winner"] = matchup.team2Id;
      matchup["loser"] = matchup.team1Id;
    } else if (matchup.team1Id === "BYE") {
      matchup["winner"] = matchup.team2Id;
      matchup["loser"] = matchup.team1Id;
    } else if (matchup.team2Id === "BYE") {
      matchup["winner"] = matchup.team1Id;
      matchup["loser"] = matchup.team2Id;
    }
  }

  for (
    let round = 2;
    round <=
    Object.keys(bracketDetails.upper).length - (!!bracketDetails.lower ? 2 : 0);
    round++
  ) {
    for (
      let j = 0;
      j < bracketDetails.upper[`round${round}`].length * 2;
      j += 2
    ) {
      const matchup = bracketDetails.upper[`round${round}`][Math.round(j / 2)];

      if (matchup.title === "Third Place") {
        continue;
      }

      matchup.team1Id = bracketDetails.upper[`round${round - 1}`][j].winner;
      matchup.team2Id = bracketDetails.upper[`round${round - 1}`][j + 1].winner;

      if (matchup.team1Score > matchup.team2Score) {
        matchup["winner"] = matchup.team1Id;
        matchup["loser"] = matchup.team2Id;
      } else if (matchup.team2Score > matchup.team1Score) {
        matchup["winner"] = matchup.team2Id;
        matchup["loser"] = matchup.team1Id;
      } else if (matchup.team1Id === "BYE") {
        matchup["winner"] = matchup.team2Id;
        matchup["loser"] = matchup.team1Id;
      } else if (matchup.team2Id === "BYE") {
        matchup["winner"] = matchup.team1Id;
        matchup["loser"] = matchup.team2Id;
      }
    }
  }

  if (!bracketDetails.lower) {
    const numberOfRounds = Object.keys(bracketDetails.upper).length;

    const thirdPlaceMatchup = bracketDetails.upper[`round${numberOfRounds}`][1];
    thirdPlaceMatchup.team1Id =
      bracketDetails.upper[`round${numberOfRounds - 1}`][0].loser;
    thirdPlaceMatchup.team2Id =
      bracketDetails.upper[`round${numberOfRounds - 1}`][1].loser;

    if (thirdPlaceMatchup.team1Score > thirdPlaceMatchup.team2Score) {
      thirdPlaceMatchup["winner"] = thirdPlaceMatchup.team1Id;
      thirdPlaceMatchup["loser"] = thirdPlaceMatchup.team2Id;
    } else if (thirdPlaceMatchup.team2Score > thirdPlaceMatchup.team1Score) {
      thirdPlaceMatchup["winner"] = thirdPlaceMatchup.team2Id;
      thirdPlaceMatchup["loser"] = thirdPlaceMatchup.team1Id;
    } else if (thirdPlaceMatchup.team1Id === "BYE") {
      thirdPlaceMatchup["winner"] = thirdPlaceMatchup.team2Id;
      thirdPlaceMatchup["loser"] = thirdPlaceMatchup.team1Id;
    } else if (thirdPlaceMatchup.team2Id === "BYE") {
      thirdPlaceMatchup["winner"] = thirdPlaceMatchup.team1Id;
      thirdPlaceMatchup["loser"] = thirdPlaceMatchup.team2Id;
    }
  }

  return bracketDetails;
};

export default updateUpperBracket;
