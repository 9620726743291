import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { auth } from "./firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import Home from "./routes/home/Home";
import Navbar from "./components/navbar/Navbar";
import Tournament from "./routes/tournament/Tournament";
import Auth from "./routes/auth/Auth";
import SignUp from "./routes/auth/signup/SignUp";
import Organize from "./routes/organize/Organize";
import Register from "./routes/register/Register";
import Planner from "./routes/planner/Planner";
import Registrants from "./routes/tournament/registrants/Registrants";
import About from "./routes/about/About";
import AddClub from "./routes/clubs/AddClub";

function App() {
  const [user, setUser] = useState(false);

  useEffect(() => {
    const checkUser = onAuthStateChanged(auth, (user) => {
      if (!!user) {
        setUser(user);
      } else {
        setUser(false);
      }
    });

    return checkUser;
  }, []);

  return (
    <BrowserRouter>
      <Navbar isLoggedIn={!!user} />
      <div className="mb-20 mt-20 flex flex-col overscroll-none">
        <Routes>
          <Route path="/" element={<Home user={user} />} />
          <Route path="/auth" element={<Auth isLoggedIn={!!user} />} />
          <Route path="/auth/signup" element={<SignUp isLoggedIn={!!user} />} />
          <Route path="/:tournamentId" element={<Tournament user={user} />} />
          <Route
            path="/:tournamentId/register"
            element={<Register user={user} />}
          />
          <Route
            path="/:tournamentId/registrants"
            element={<Registrants user={user} />}
          />
          <Route path="/organize" element={<Organize user={user} />} />
          <Route path="/planner" element={<Planner />} />
          <Route path="/about" element={<About />} />
          <Route path="/clubs" element={<AddClub isLoggedIn={!!user} />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
