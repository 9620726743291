import { useTournament, useTournamentUpdate } from "../TournamentContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { db } from "../../../firebase/config";
import { ref, set, update } from "firebase/database";
import sortTeams from "./logic/sortTeams";
import generateNextRound from "./logic/generateNextRound";
// import Button from "../../../components/Button";
import { IoConstruct } from "react-icons/io5";

const GenerateSwissRound = ({ updateSchedule }) => {
  const { tournament, tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState(false);

  const writeSchedule = async () => {
    const teams = structuredClone(tournament.teams);

    if (Object.keys(tournament.teams).length % 2 === 1) {
      teams["bye"] = { id: "bye", teamName: "BYE" };

      const teamsReference = ref(db, `tournaments/${tournamentId}/teams`);
      const waitlistReference = ref(db, `tournaments/${tournamentId}/waitlist`);

      try {
        await update(teamsReference, { bye: { id: "bye", teamName: "BYE" } });
        await set(waitlistReference, null);
      } catch (error) {
        console.log(error);
      }
    }

    const roundNumber = !!tournament.schedule
      ? Object.keys(tournament.schedule).length + 1
      : 1;

    const sortedTeamIds = sortTeams(teams, !!!tournament.schedule);

    const nextRound = generateNextRound(sortedTeamIds, roundNumber);

    const groupsReference = ref(db, `tournaments/${tournamentId}/groups`);
    const scheduleReference = ref(db, `tournaments/${tournamentId}/schedule`);

    try {
      await update(groupsReference, { group1: sortedTeamIds });
      await update(scheduleReference, { [`day${roundNumber}`]: nextRound });
    } catch (error) {
      console.log(error);
    }

    if (!!!tournament.schedule) {
      const detailsReference = ref(db, `tournaments/${tournamentId}/details`);

      try {
        await update(detailsReference, { isSwiss: true });
      } catch (error) {
        console.log(error);
      }

      window.location.reload();
    } else {
      updateTournament("groups", { group1: sortedTeamIds });

      updateTournament("schedule", {
        ...tournament.schedule,
        [`day${roundNumber}`]: nextRound,
      });

      updateSchedule({
        ...tournament.schedule,
        [`day${roundNumber}`]: nextRound,
      });

      setConfirmation(false);
    }
  };

  return (
    <>
      {/* <div onClick={() => setConfirmation(true)} className="mx-2">
        <Button
          i18nPath={
            !!tournament.schedule
              ? "page.tournament.swiss.generate.title"
              : "page.tournament.swiss.swissRounds"
          }
          color="blue"
          hover={true}
        />
      </div> */}
      <div className="relative">
        <button
          className="mx-2 bg-gray-400 text-white text-lg font-bold py-2 px-4 rounded"
          disabled={true}
        >
          {t("page.tournament.swiss.swissRounds")}
        </button>
        <IoConstruct className="absolute top-0.5 right-2.5" />
      </div>
      {confirmation && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden inline-block align-middle h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 p-6 pb-4">
                <div className="mt-3 mt-0 ml-4 text-left">
                  <h3
                    className="text-lg font-medium text-gray-900 text-left"
                    id="modal-headline"
                  >
                    {!!tournament.schedule
                      ? t("page.tournament.swiss.generate.title")
                      : t("page.tournament.swiss.create.title")}
                  </h3>
                  <div className="mt-2 justify-start">
                    <p className="text-sm text-gray-500">
                      {!!tournament.schedule
                        ? t("page.tournament.swiss.generate.label")
                        : t("page.tournament.swiss.create.label")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 px-6 flex justify-end">
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-0 w-auto text-sm"
                >
                  {t("misc.cancel")}
                </button>
                <button
                  onClick={() => writeSchedule()}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ml-3 w-auto text-sm"
                >
                  {t("misc.confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GenerateSwissRound;
