import { useState } from "react";
import { useTranslation } from 'react-i18next';

const Planner = () => {
  const { t } = useTranslation();
  const [numberOfTeams, setNumberOfTeams] = useState(12);
  const [numberOfGroups, setNumberOfGroups] = useState(2);
  const [gameLength, setGameLength] = useState(15);
  const [interval, setInterval] = useState(6);
  const [numberOfCourts, setNumberOfCourts] = useState(1);

  const numberOfGames = (numberOfTeams, numberOfGroups) => {
    return (
      (((numberOfTeams / numberOfGroups) *
        (numberOfTeams / numberOfGroups - 1)) /
        2) *
      numberOfGroups
    );
  };

  const courtTime = (numberOfGames, gameLength, interval, numberOfCourts) => {
    return (
      (Math.ceil(numberOfGames / numberOfCourts) * (gameLength + interval)) / 60
    );
  };

  const gameFrequency = (
    numberOfGames,
    gameLength,
    interval,
    numberOfCourts,
    numberOfGroups,
    numberOfTeams
  ) => {
    return (
      courtTime(numberOfGames, gameLength, interval, numberOfCourts) /
      (Math.ceil(numberOfTeams / numberOfGroups) - 1) /
      numberOfCourts
    );
  };

  const teams = [numberOfTeams - 2, numberOfTeams, numberOfTeams + 2];
  const times = [gameLength - 3, gameLength, gameLength + 3];

  const great = "bg-blue-300";
  const good = "bg-green-300";
  const okay = "bg-yellow-300";
  const bad = "bg-red-300";

  return (
    <div className="flex justify-center mt-8">
      <div className="flex flex-col items-center w-11/12">
        <div className="text-center">
          <h1 className="text-4xl font-bold">
            { t('page.planner.title') }
          </h1>
          <p>
            { t('page.planner.subTitle') }
          </p>
        </div>
        <label className="text-gray-700 text-lg font-semibold">
        { t('page.planner.form.teamAmount') }
        </label>
        <input
          onChange={(event) =>
            setNumberOfTeams(
              event.target.value ? Number(event.target.value) : 2
            )
          }
          className="text-center shadow appearance-none border rounded w-[50px] py-2 pl-2 text-gray-700 text-center focus:outline-none focus:shadow-outline"
          defaultValue={numberOfTeams}
          type="number"
        />
        <label className="text-gray-700 text-lg font-semibold">
          { t('page.planner.form.gameLength') }
        </label>
        <input
          onChange={(event) =>
            setGameLength(event.target.value ? Number(event.target.value) : 3)
          }
          className="text-center shadow appearance-none border rounded w-[50px] py-2 pl-2 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
          defaultValue={gameLength}
          type="number"
        />
        <label className="text-gray-700 text-lg font-semibold">
        { t('page.planner.form.gameInterval') }
        </label>
        <input
          onChange={(event) =>
            setInterval(event.target.value ? Number(event.target.value) : 0)
          }
          className="text-center shadow appearance-none border rounded w-[50px] py-2 pl-2 text-gray-700 text-center leading-tight focus:outline-none focus:shadow-outline"
          defaultValue={interval}
          type="number"
        />
        <div className="flex flex-col items-center">
          <label className="text-gray-700 text-lg font-semibold">
            { t('page.planner.form.groupAmount') }
          </label>
          <select
            onChange={(event) => setNumberOfGroups(Number(event.target.value))}
            className="w-[50px] h-[38px] text-center shadow border rounded text-gray-700 focus:outline-none focus:shadow-outline"
            type="dropdown"
            defaultValue={2}
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
          </select>
        </div>
        <div className="flex flex-col items-center">
          <label className="text-gray-700 text-lg font-semibold">
          { t('page.planner.form.courtAmount') }
          </label>
          <select
            onChange={(event) => setNumberOfCourts(Number(event.target.value))}
            className="w-[50px] h-[38px] text-center shadow border rounded text-gray-700 focus:outline-none focus:shadow-outline"
            type="dropdown"
            defaultValue={1}
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
          </select>
        </div>
        <h1 className="text-xl font-bold mt-4">
          { t('page.planner.result.titles.courtTime') }
        </h1>
        <div className="flex justify-center items-center flex-wrap my-2">
          <div className="flex items-center m-1">
            <div className={`${great} border h-[10px] w-[10px] mr-1`} />
            <p>
              { t('page.planner.result.legend.tooMuch') }
              </p>
          </div>
          <div className="flex items-center m-1">
            <div className={`${good} border h-[10px] w-[10px] mr-1`} />
            <p>
              { t('page.planner.result.legend.smooth') }
            </p>
          </div>
          <div className="flex items-center m-1">
            <div className={`${okay} border h-[10px] w-[10px] mr-1`} />
            <p>
              { t('page.planner.result.legend.flush') }
            </p>
          </div>
          <div className="flex items-center m-1">
            <div className={`${bad} border h-[10px] w-[10px] mr-1`} />
            <p>
              { t('page.planner.result.legend.notEnough') }  
            </p>
          </div>
        </div>
        <table className="text-center w-full max-w-md">
          <thead className="text-gray-700">
            <tr>
              <th>
                { t('page.planner.result.table.teamAmount') }
              </th>
              <th>
                { t('page.planner.result.table.gameAmount') }
              </th>
              <th>
                { t('page.planner.result.table.gameDuration', {gameLength: gameLength - 3}) }
              </th>
              <th>
                { t('page.planner.result.table.gameDuration', {gameLength}) }
              </th>
              <th>
                { t('page.planner.result.table.gameDuration', {gameLength: gameLength + 3}) }
              </th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team, index) => (
              <tr key={team}>
                <td>{team}</td>
                <td>{Math.ceil(numberOfGames(team, numberOfGroups))}</td>
                {times.map((time, i) => {
                  const value = courtTime(
                    numberOfGames(team, numberOfGroups),
                    time,
                    interval,
                    numberOfCourts
                  ).toFixed(1);
                  return (
                    <td
                      key={time}
                      className={`${
                        value <= 8
                          ? great
                          : value <= 13
                          ? good
                          : value <= 17
                          ? okay
                          : bad
                      } ${
                        index === 1 &&
                        i === 1 &&
                        "text-lg font-bold border border-white"
                      }`}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <h1 className="text-xl font-bold mt-4">
          { t('page.planner.result.titles.gameFrequency') }
        </h1>
        <table className="text-center w-full max-w-md">
          <thead className="text-gray-700">
            <tr>
              <th>
                { t('page.planner.result.table.teamAmount') }
              </th>
              <th>
                { t('page.planner.result.table.gameAmount') }
              </th>
              <th>
                { t('page.planner.result.table.gameDuration', {gameLength: gameLength - 3}) }
              </th>
              <th>
                { t('page.planner.result.table.gameDuration', {gameLength}) }
              </th>
              <th>
                { t('page.planner.result.table.gameDuration', {gameLength: gameLength + 3}) }
              </th>
            </tr>
          </thead>
          <tbody>
            {teams.map((team, index) => (
              <tr key={team}>
                <td>{team}</td>
                <td>{Math.ceil(numberOfGames(team, numberOfGroups))}</td>
                {times.map((time, i) => {
                  const value = gameFrequency(
                    numberOfGames(team, numberOfGroups),
                    time,
                    interval,
                    numberOfCourts,
                    numberOfGroups,
                    team
                  ).toFixed(1);
                  return (
                    <td
                      key={time}
                      className={`${
                        value <= 1.5
                          ? great
                          : value <= 2
                          ? good
                          : value <= 2.5
                          ? okay
                          : bad
                      } ${
                        index === 1 &&
                        i === 1 &&
                        "text-lg font-bold border border-white"
                      }`}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Planner;
