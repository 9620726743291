import { useState } from "react";
import { db } from "../../../../firebase/config";
import { storage } from "../../../../firebase/config";
import { ref, set } from "firebase/database";
import { ref as storage_ref, deleteObject } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

const DeleteTournament = ({ tournamentId, imageFileName }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState(false);

  const deleteTournament = async (event) => {
    event.preventDefault();

    const tournamentReference = ref(db, `tournaments/${tournamentId}`);
    const homeReference = ref(db, `home/${tournamentId}`);
    const registrationReference = ref(db, `registration/${tournamentId}`);

    const imageReference = storage_ref(storage, `images/${imageFileName}`);

    try {
      await set(tournamentReference, {
        tournamentId: null,
      });

      await set(homeReference, {
        tournamentId: null,
      });

      await set(registrationReference, {
        tournamentId: null,
      });

      await deleteObject(imageReference);
    } catch (error) {
      console.log(error);
    }

    navigate("/");
  };

  return (
    <>
      <RiDeleteBinLine
        onClick={() => setConfirmation(true)}
        className="h-8 w-8 hover:fill-red-700"
      />
      {confirmation && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex justify-center min-w-screen pt-40 px-4 pb-20">
            <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
            <form
              onSubmit={(event) => deleteTournament(event)}
              className="w-11/12 max-w-xl rounded-lg text-left overflow-hidden shadow-xl transform transition-all"
            >
              <div className="bg-white px-8 py-6">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {t("page.tournament.details.deleteTournament.delete")}
                </h3>
                <div className="mt-4">
                  <p className="text-sm text-gray-500 whitespace-pre-line">
                    {t("page.tournament.details.deleteTournament.firstLine")}
                  </p>
                  <p className="text-sm text-gray-500 whitespace-pre-line">
                    {t("page.tournament.details.deleteTournament.secondLine")}
                  </p>
                </div>
              </div>
              <div className="flex justify-end w-full bg-gray-50 px-4 py-3">
                <button
                  type="submit"
                  className="rounded-md border border-transparent shadow-sm px-4 py-2 mr-4 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {t("misc.confirm")}
                </button>
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  {t("misc.cancel")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteTournament;
