import { useState, useEffect } from "react";
import { db } from "../../firebase/config";
import { ref, set, onValue } from "firebase/database";
import { useTranslation } from "react-i18next";
import NotSignedIn from "../../components/notSignedIn/NotSignedIn";
import SaveSection from "../tournament/components/SaveSection";
import { IoClose } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa";

const AddClub = ({ isLoggedIn }) => {
  const { t } = useTranslation();

  const [clubs, setClubs] = useState([]);
  const [newClub, setNewClub] = useState("");
  const [save, setSave] = useState(false);

  useEffect(() => {
    const clubsRef = ref(db, `/clubs/`);

    const getClubs = async () => {
      try {
        onValue(
          clubsRef,
          (snapshot) => {
            const data = snapshot.val();
            setClubs(data.sort());
          },
          { onlyOnce: true }
        );
      } catch (error) {
        console.log(error);
      }
    };

    getClubs();
  }, []);

  const removeClubFromState = (removedClub) => {
    setClubs((prev) => prev.filter((club) => club !== removedClub));
    setSave(true);
  };

  const addClubToState = (event) => {
    event.preventDefault();

    setClubs((prev) => [...prev, newClub]);
    setNewClub("");
    setSave(true);
  };

  const writeClubs = async () => {
    const sortedClubs = clubs.sort();

    const clubsReference = ref(db, `clubs/`);

    try {
      set(clubsReference, clubs);
    } catch (error) {
      console.log(error);
    }

    setClubs(sortedClubs);
    setSave(false);
  };

  if (!isLoggedIn) {
    return <NotSignedIn action={"You must be signed in"} />;
  }

  return (
    <div className="w-full flex justify-center">
      <div className="w-11/12 flex flex-col justify-center items-center mt-24">
        <SaveSection saveFunction={writeClubs} saveButton={save} />
        <div className="flex items-center mt-4">
          <form onSubmit={(event) => addClubToState(event)}>
            <label>{t("page.clubs.new")}:</label>
            <input
              onChange={(event) => setNewClub(event.target.value)}
              value={newClub}
              className="mx-2 border"
            />
            <button type="submit">
              <FaArrowRight className="cursor-pointer" />
            </button>
          </form>
        </div>
        <div className="flex flex-col flex-wrap h-[600px] mt-4">
          {clubs.map((club) => (
            <div key={club} className="flex items-center m-1">
              <IoClose
                onClick={() => removeClubFromState(club)}
                className="h-5 w-5 cursor-pointer"
              />
              <p className="text-lg">{club}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddClub;
