import React from "react";
import { useTranslation } from "react-i18next";

const MatchTimesInput = ({
  detail,
  i18NextValue,
  onChange,
  defaultValue,
  placeholder,
  type,
  day,
  index,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center mx-1">
      {day ? (
        <label className="text-sm font-bold">{day.toUpperCase()}</label>
      ) : (
        <label className="text-sm font-bold">
          {t(`page.tournament.schedule.gameTimes.${i18NextValue}`)}
        </label>
      )}
      <input
        type={type}
        className="border rounded w-[120px] text-center"
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(event) => {
          onChange(detail, event.target.value, index);
        }}
        required
      />
    </div>
  );
};

export default MatchTimesInput;
