import {
  useTournament,
  useTournamentUpdate,
} from "../routes/tournament/TournamentContext";
import { db } from "../firebase/config";
import { ref, update } from "firebase/database";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const MakeSectionPublic = ({ i18nTitle, i18nText, isSectionPublic }) => {
  const { tournament, tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState(false);

  const updateVisibility = async () => {
    const detailsReference = ref(db, `tournaments/${tournamentId}/details`);

    try {
      await update(detailsReference, {
        isTournamentPublic: !tournament.details.isTournamentPublic,
      });
    } catch (error) {
      console.log(error);
    }

    if (isSectionPublic === "isTournamentPublic") {
      const homeReference = ref(db, `home/${tournamentId}`);
      try {
        await update(homeReference, {
          isTournamentPublic: !tournament.details.isTournamentPublic,
        });
      } catch (error) {
        console.log(error);
      }
    }

    updateTournament("details", {
      ...tournament.details,
      [isSectionPublic]: true,
    });

    setConfirmation(false);
  };

  return (
    <>
      <div
        onClick={() => setConfirmation(true)}
        className="text-red-500 font-semibold p-2 mb-4 cursor-pointer
            border border-red-600 rounded-md"
      >
        {t(i18nTitle)}
      </div>
      {confirmation && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden inline-block align-middle h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 p-6 pb-4">
                <div className="flex items-start">
                  <div className="mt-3 text-left mt-0 ml-4 text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      {t(i18nTitle)}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 whitespace-pre-line">
                        {t(i18nText)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 px-6 flex justify-end">
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-0 w-auto text-sm"
                >
                  {t("misc.cancel")}
                </button>
                <button
                  onClick={() => updateVisibility()}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ml-3 w-auto text-sm"
                >
                  {t("misc.confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MakeSectionPublic;
