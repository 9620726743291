const Position = ({ teams, teamId }) => {
  return (
    <tr>
      <td className="font-semibold text-wrap leading-4">
        {teams[teamId].teamName}
      </td>
      <td>{teams[teamId].wins || 0}</td>
      <td>{teams[teamId].losses || 0}</td>
      <td>{teams[teamId].draws || 0}</td>
      <td>{teams[teamId].delta || 0}</td>
      <td>{teams[teamId].goals || 0}</td>
    </tr>
  );
};

export default Position;
