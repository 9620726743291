const generateUpperBracket = (sortedTeams, numberOfRounds) => {
  const upper = {};
  let bracketSize = Math.pow(2, numberOfRounds);

  let seeding = [[1, 2]];

  for (let round = 1; round < numberOfRounds; round++) {
    const roundMatches = [];
    const sum = Math.pow(2, round + 1) + 1;

    for (let i = 0; i < seeding.length; i++) {
      let home = seeding[i][0];
      let away = sum - seeding[i][0];
      roundMatches.push([home, away]);
      home = sum - seeding[i][1];
      away = seeding[i][1];
      roundMatches.push([home, away]);
    }
    seeding = roundMatches;
  }

  for (let round = 1; round <= numberOfRounds; round++) {
    upper[`round${round}`] = [];

    for (let j = 0; j < bracketSize / 2; j++) {
      if (round === 1) {
        upper[`round${round}`].push({
          team1Id: sortedTeams[seeding[j][0] - 1]
            ? sortedTeams[seeding[j][0] - 1]
            : "BYE",
          team2Id: sortedTeams[seeding[j][1] - 1]
            ? sortedTeams[seeding[j][1] - 1]
            : "BYE",
          team1Score: "",
          team2Score: "",
          winner: "tbd",
          loser: "tbd",
        });
      } else {
        if (round === numberOfRounds) {
          upper[`round${round}`].push({
            team1Id: "tbd",
            team2Id: "tbd",
            team1Score: "",
            team2Score: "",
            winner: "tbd",
            loser: "tbd",
            title: "Finals",
          });
        } else {
          upper[`round${round}`].push({
            team1Id: "tbd",
            team2Id: "tbd",
            team1Score: "",
            team2Score: "",
            winner: "tbd",
            loser: "tbd",
          });
        }
      }
    }
    bracketSize = bracketSize / 2;
  }

  return upper;
};

export default generateUpperBracket;
