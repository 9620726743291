import { useTournament } from "../../TournamentContext";
import { db } from "../../../../firebase/config";
import { ref, set } from "firebase/database";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import generateBracket from "../logic/generateBracket";
import updateBracket from "../logic/updateBracket";
import Button from "../../../../components/Button";

const CreateBracket = ({ groups, teams }) => {
  const { tournamentId } = useTournament();
  const { t } = useTranslation();
  const numberOfTeams = Object.keys(teams).length;

  const [confirmation, setConfirmation] = useState(false);
  const [bracketType, setBracketType] = useState("single");
  const [numberOfPlayoffTeams, setNumberOfPlayoffTeams] =
    useState(numberOfTeams);
  const [numberOfHayesTeams, setNumberOfHayesTeams] = useState(4);

  const writeBracket = async () => {
    const groupsClone = structuredClone(groups);
    const bracket = updateBracket(
      generateBracket(
        groupsClone,
        teams,
        bracketType,
        numberOfPlayoffTeams,
        numberOfHayesTeams
      ),
      bracketType
    );

    const bracketReference = ref(db, `tournaments/${tournamentId}/bracket`);

    try {
      await set(bracketReference, bracket);
    } catch (error) {
      console.log(error);
    }

    window.location.reload();
  };

  const playoffTeamsSelect = () => {
    const array = [];

    for (let i = 6; i < numberOfTeams; i = i + 2) {
      array.push(i);
    }

    return array;
  };

  return (
    <div>
      <div onClick={() => setConfirmation(true)}>
        <Button
          i18nPath="page.tournament.bracket.create.title"
          color="blue"
          hover={true}
        />
      </div>
      {confirmation && teams && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden inline-block align-middle h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-xl w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white pt-5 pb-4 p-6">
                <div className="flex items-start">
                  <div className="mt-3 text-left mt-0 ml-4 text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      {t("page.tournament.bracket.create.title")}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 whitespace-pre-line mb-2">
                        {t("page.tournament.bracket.create.warning")}
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 whitespace-pre-line">
                        {t("page.tournament.bracket.create.type")}
                      </p>
                    </div>
                    <div className="flex flex-wrap mt-2">
                      <button
                        onClick={() => setBracketType("single")}
                        className={`${
                          bracketType === "single" ? "bg-sky-300" : ""
                        } border p-1 px-4 m-1 rounded-full`}
                      >
                        {t("page.tournament.bracket.create.single")}
                      </button>
                      <button
                        onClick={() => setBracketType("double")}
                        className={`${
                          bracketType === "double" ? "bg-sky-300" : ""
                        } border p-1 px-4 m-1 rounded-full`}
                      >
                        {t("page.tournament.bracket.create.double")}
                      </button>
                      <button
                        onClick={() => setBracketType("hayesSingle")}
                        className={`${
                          bracketType === "hayesSingle" ? "bg-sky-300" : ""
                        } border p-1 px-4 m-1 rounded-full
                        ${numberOfTeams < 8 ? "bg-gray-300" : ""}`}
                        disabled={numberOfTeams < 8}
                      >
                        {t("page.tournament.bracket.create.hayesSingle")}
                      </button>
                      <button
                        onClick={() => setBracketType("hayesDouble")}
                        className={`${
                          bracketType === "hayesDouble" ? "bg-sky-300" : ""
                        } border p-1 px-4 m-1 rounded-full
                        ${numberOfTeams < 8 ? "bg-gray-300" : ""}`}
                        disabled={numberOfTeams < 8}
                      >
                        {t("page.tournament.bracket.create.hayesDouble")}
                      </button>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 whitespace-pre-line">
                        {t(
                          "page.tournament.bracket.create.numberOfPlayoffTeams"
                        )}
                      </p>
                    </div>
                    <div className="flex flex-wrap mt-2">
                      <select
                        onChange={(event) =>
                          setNumberOfPlayoffTeams(event.target.value)
                        }
                        className="shadow border rounded py-2 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="dropdown"
                        defaultValue={numberOfTeams}
                      >
                        <option value={numberOfTeams}>All</option>
                        {playoffTeamsSelect().map((number) => (
                          <option key={number} value={number}>
                            {number}
                          </option>
                        ))}
                      </select>
                    </div>
                    {bracketType.includes("hayes") && (
                      <>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500 whitespace-pre-line">
                            {t(
                              "page.tournament.bracket.create.numberOfUpperBracketTeams"
                            )}
                          </p>
                        </div>
                        <div className="flex flex-wrap mt-2">
                          <select
                            onChange={(event) =>
                              setNumberOfHayesTeams(event.target.value)
                            }
                            className="shadow border rounded py-2 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="dropdown"
                            defaultValue={4}
                          >
                            <option value={4}>4</option>
                            {numberOfPlayoffTeams > 8 && (
                              <option value={8}>8</option>
                            )}
                            {numberOfPlayoffTeams > 16 && (
                              <option value={16}>16</option>
                            )}
                            {numberOfPlayoffTeams > 32 && (
                              <option value={32}>32</option>
                            )}
                            {numberOfPlayoffTeams > 64 && (
                              <option value={64}>64</option>
                            )}
                            {numberOfPlayoffTeams > 128 && (
                              <option value={128}>128</option>
                            )}
                          </select>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div></div>
              <div className="bg-gray-50 px-4 py-3 px-6 flex justify-end">
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-0 w-auto text-sm"
                >
                  {t("misc.cancel")}
                </button>
                <button
                  onClick={() => writeBracket()}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ml-3 w-auto text-sm"
                >
                  {t("misc.confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateBracket;
