import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { ref, update } from "firebase/database";
import { db } from "../../../../firebase/config";
import SaveSection from "../../components/SaveSection";

const UpdateGroups = ({ groups, saveButton, updateSaveButton }) => {
  const { tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();

  const writeGroups = async () => {
    for (const group in groups) {
      if (groups[group].length < 2) {
        alert("Each group must have at least 2 teams");
        return;
      }
    }

    const groupsReference = ref(db, `tournaments/${tournamentId}/groups/`);

    try {
      await update(groupsReference, groups);
    } catch (error) {
      console.log(error);
    }

    updateTournament("groups", groups);

    updateSaveButton(false);
  };

  return <SaveSection saveFunction={writeGroups} saveButton={saveButton} />;
};

export default UpdateGroups;
