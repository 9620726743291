import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { FaCalendar } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { LiaCrownSolid } from "react-icons/lia";
import { useTranslation } from "react-i18next";

const TournamentCard = ({ id, tournament, isUserOrganizer }) => {
  const { t } = useTranslation();
  return (
    <Link
      to={`/${id}`}
      className="flex flex-col w-11/12 max-w-sm m-3 shadow-md rounded-2xl
      hover:scale-[1.02] transform transition-transform duration-300 ease-in-out"
    >
      <div className="relative">
        {tournament.imageSrc ? (
          <img
            src={tournament.imageSrc}
            className="object-none h-[200px] w-full rounded-t-2xl"
            alt="tournament-card-poster"
          />
        ) : (
          <div className="h-[200px] w-full bg-black rounded-t-2xl" />
        )}
        {tournament.isRegistrationOpen && (
          <div
            className="absolute bottom-0 z-2 bg-green-600
            font-bold text-white p-1 px-2 m-3 rounded-xl"
          >
            {t("component.tournamentCard.registrationsOpen")}
          </div>
        )}
        {isUserOrganizer && (
          <>
            <LiaCrownSolid className="absolute top-2 left-2 h-9 w-9 fill-yellow-200 bg-blue-600 p-1 rounded-full border border-black shadow-lg" />

            {!tournament.isTournamentPublic && (
              <FaEyeSlash className="absolute top-2 right-2 h-9 w-9 fill-black bg-white p-1 rounded-full border border-black shadow-lg" />
            )}
          </>
        )}
      </div>
      <div className="p-2 bg-stone-200/70">
        <div className="flex justify-center text-center mb-2">
          <p className="font-bold mt-1">{tournament.name}</p>
        </div>
        <div className="flex items-center">
          <FaLocationDot className="mx-2 fill-slate-500" />
          <p className="text-sm text-slate-500 break-words">
            {tournament.location}
          </p>
        </div>
        <div className="flex items-center">
          <FaCalendar className="mx-2 fill-slate-500" />
          <p className="mr-2 text-sm text-slate-500">{tournament.startDate}</p>
          <div>{"-"}</div>
          <p className="ml-2 text-sm text-slate-500">{tournament.endDate}</p>
        </div>
      </div>
    </Link>
  );
};

export default TournamentCard;
