import { useTournament } from "../TournamentContext";
import Position from "./components/Position";
import { useTranslation } from "react-i18next";

const Standings = ({ user }) => {
  const { tournament } = useTournament();
  const { t } = useTranslation();

  const isUserOrganizer = tournament.details.organizerEmails.includes(
    user.email
  );

  const groupBG = (index) => {
    switch (index) {
      case 0:
        return "bg-blue-500";
      case 1:
        return "bg-red-500";
      case 2:
        return "bg-green-500";
      case 3:
        return "bg-yellow-500";
      case 4:
        return "bg-purple-500";
      case 5:
        return "bg-orange-500";
      default:
        return "bg-white";
    }
  };

  if (
    (!isUserOrganizer && !tournament.details.isSchedulePublic) ||
    !!!tournament.schedule
  ) {
    return;
  }

  return (
    <div className="flex flex-wrap justify-center mb-8">
      <div
        className="w-full text-center font-bold text-2xl
          border-b-2 border-slate-600 mb-2"
      >
        {`${t("page.tournament.standings.title")} ${
          tournament.details.isSchedulePublic ? "" : t("misc.notPublic")
        }`}
      </div>
      {Object.values(tournament.groups).map((group, index) => {
        return (
          <div
            key={index}
            className="flex flex-col items-center w-full max-w-xl
              border rounded-xl shadow-md m-2"
          >
            <p
              className={`font-bold text-white ${groupBG(index)} py-1
                border-b rounded-t-xl w-full text-center`}
            >{`Group ${index + 1}`}</p>
            <table className="w-11/12 text-center m-0.5">
              <thead>
                <tr>
                  <th className="w-2/3">
                    {t("page.tournament.standings.team")}
                  </th>
                  <th>W</th>
                  <th>L</th>
                  <th>D</th>
                  <th>Δ</th>
                  <th>G</th>
                </tr>
              </thead>
              <tbody>
                {group.map((teamId) => (
                  <Position
                    key={teamId}
                    teams={tournament?.teams}
                    teamId={teamId}
                  />
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default Standings;
