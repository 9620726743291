import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { useState } from "react";
import { db } from "../../../../firebase/config";
import { ref, push, set } from "firebase/database";
import { IoPeopleOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Tip from "../../../../components/Tip";

const AddTeams = ({ teams }) => {
  const { tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();
  const { t } = useTranslation();

  const [teamsToAdd, setTeamsToAdd] = useState(8);
  const [confirmation, setConfirmation] = useState(false);

  const numberOfTeams = teams ? Object.keys(teams).length : 0;

  const writeFakeTeams = async (event) => {
    event.preventDefault();

    const reference = ref(db, `tournaments/${tournamentId}/teams/`);

    const fakeTeam = {
      player1: "Player 1",
      player2: "Player 2",
      player3: "Player 3",
    };

    const fakeTeams = {};

    for (let i = 0; i < teamsToAdd; i++) {
      const addedItem = await push(reference);

      try {
        await set(addedItem, {
          ...fakeTeam,
          id: addedItem.key,
          teamName: `TEAM ${numberOfTeams + i + 1}`,
        });

        fakeTeams[addedItem.key] = {
          ...fakeTeam,
          id: addedItem.key,
          teamName: `TEAM ${numberOfTeams + i + 1}`,
        };
      } catch (error) {
        console.log(error);
      }
    }

    updateTournament("teams", {
      ...teams,
      ...fakeTeams,
    });

    setConfirmation(false);
  };

  return (
    <>
      <div
        className="group absolute top-1.5 left-0 cursor-pointer"
        onClick={() => setConfirmation(true)}
      >
        <IoPeopleOutline className="h-8 w-8" />
        <div className="absolute bottom-8 left-[-24px] sm:hidden">
          <Tip text={"Create Mock Teams"} />
        </div>
      </div>
      {confirmation && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex justify-center min-w-screen pt-40 px-4 pb-20">
            <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
            <form
              onSubmit={(event) => writeFakeTeams(event)}
              className="w-11/12 max-w-xl rounded-lg text-left overflow-hidden shadow-xl transform transition-all"
            >
              <div className="bg-white px-8 py-6">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {t("page.tournament.teams.addMockTeams.title")}
                </h3>
                <div className="mt-6">
                  <label className="block text-gray-700 text-sm font-semibold mb-2">
                    {t("page.tournament.teams.addMockTeams.label")}
                  </label>
                  <select
                    onChange={(event) => setTeamsToAdd(event.target.value)}
                    className="shadow border rounded py-2 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="dropdown"
                    defaultValue={8}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                    <option value={11}>11</option>
                    <option value={12}>12</option>
                    <option value={13}>13</option>
                    <option value={14}>14</option>
                    <option value={15}>15</option>
                    <option value={16}>16</option>
                  </select>
                </div>
              </div>
              <div className="flex justify-end w-full bg-gray-50 px-4 py-3">
                <button
                  type="submit"
                  className="rounded-md border border-transparent shadow-sm px-4 py-2 mr-4 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 cursor-pointer"
                >
                  {t("misc.confirm")}
                </button>
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 cursor-pointer"
                >
                  {t("misc.cancel")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddTeams;
