import React, { useState } from "react";
import { useTournament, useTournamentUpdate } from "../TournamentContext";
import { db } from "../../../firebase/config";
import { ref, update } from "firebase/database";
import CreateBracket from "./components/CreateBracket";
import Matchup from "./components/Matchup";
import updateBracket from "./logic/updateBracket";
import MakeBracketPublic from "./components/MakeBracketPublic";
import DeleteBracket from "./components/DeleteBracket";
import SaveSection from "../components/SaveSection";
import { useTranslation } from "react-i18next";

const Bracket = ({ user }) => {
  const { tournament, tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();
  const { t } = useTranslation();

  const [saveButton, setSaveButton] = useState(false);

  const details = tournament?.details;
  const bracket = tournament?.bracket;
  const isUserOrganizer = details.organizerEmails.includes(user.email);

  const toggleSaveButton = (isVisible) => {
    setSaveButton(isVisible);
  };

  const writeUpdatedBracket = async () => {
    const bracketReference = ref(db, `tournaments/${tournamentId}/bracket/`);

    const updatedBracket = updateBracket(structuredClone(bracket));

    try {
      await update(bracketReference, updatedBracket);
    } catch (error) {
      console.log(error);
    }

    updateTournament("bracket", updatedBracket);

    toggleSaveButton(false);
  };

  if (
    (!isUserOrganizer && !details.isBracketPublic) ||
    !!!tournament.schedule
  ) {
    return;
  }

  if (!!!bracket && isUserOrganizer) {
    return (
      <div className="flex flex-col items-center w-full mb-8">
        <div
          className="w-full text-center text-2xl font-bold
          border-b-2 border-slate-600 mb-4"
        >
          {`${t("page.tournament.bracket.title")} ${t("misc.notPublic")}`}
        </div>
        <CreateBracket groups={tournament?.groups} teams={tournament?.teams} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full mb-8">
      <div className="w-full text-center border-b-2 border-slate-600 mb-4 relative">
        <p className="font-bold text-2xl">
          {`${t("page.tournament.bracket.title")} ${
            details.isBracketPublic ? "" : t("misc.notPublic")
          }`}
        </p>
        {isUserOrganizer && (
          <DeleteBracket
            tournament={tournament}
            updateTournament={updateTournament}
            tournamentId={tournamentId}
          />
        )}
      </div>
      {!details.isBracketPublic && <MakeBracketPublic details={details} />}
      {!!bracket && (
        <div className="flex flex-col justify-between w-full mb-8 overflow-x-auto">
          {Object.keys(bracket)
            .reverse()
            .map((bracketLevel, index1) => (
              <div key={index1}>
                <div className="mt-4 ml-4 font-bold">
                  {`${bracketLevel.toUpperCase()} BRACKET`}
                </div>
                <div className="flex py-4 w-full">
                  {Object.values(bracket[bracketLevel]).map((round, index) => (
                    <div
                      key={index}
                      className={`flex flex-col justify-around relative`}
                    >
                      {round.map((matchup, i) => (
                        <Matchup
                          key={i}
                          matchup={matchup}
                          toggleSaveButton={toggleSaveButton}
                          bracketLevel={bracketLevel}
                          roundNumber={index + 1}
                          matchPosition={i}
                          isOrganizer={isUserOrganizer}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      )}
      {isUserOrganizer && !!tournament.bracket && (
        <SaveSection
          saveFunction={writeUpdatedBracket}
          saveButton={saveButton}
        />
      )}
    </div>
  );
};

export default Bracket;
