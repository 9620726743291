import { ref, onValue, update } from "firebase/database";
import { db } from "../../../firebase/config";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import NotSignedIn from "../../../components/notSignedIn/NotSignedIn";
import SaveSection from "../components/SaveSection";
import { useTranslation } from "react-i18next";

const Registrants = ({ user }) => {
  const { tournamentId } = useParams();
  const [organizerEmails, setOrganizerEmails] = useState([]);
  const { t } = useTranslation();

  const [teams, setTeams] = useState([]);
  const [saveButton, setSaveButton] = useState(false);

  const updateTeams = (teamId, key, event) => {
    setTeams((prev) => ({
      ...prev,
      [teamId]: {
        ...prev[teamId],
        [key]: event.target.value,
      },
    }));

    if (!saveButton) {
      setSaveButton(true);
    }
  };

  const writeTeams = async () => {
    const teamsReference = ref(db, `tournaments/${tournamentId}/teams/`);

    try {
      await update(teamsReference, teams);
    } catch (error) {
      console.log(error);
    }

    setSaveButton(false);
  };

  useEffect(() => {
    const organizerEmailsRef = ref(
      db,
      `/tournaments/${tournamentId}/details/organizerEmails`
    );
    const teamsRef = ref(db, `/tournaments/${tournamentId}/teams`);

    const getOrganizerEmails = async () => {
      try {
        onValue(
          organizerEmailsRef,
          (snapshot) => {
            const data = snapshot.val();
            setOrganizerEmails(data);
          },
          { onlyOnce: true }
        );
      } catch (error) {
        console.log(error);
      }
    };

    const getTeams = async () => {
      try {
        onValue(
          teamsRef,
          (snapshot) => {
            const data = snapshot.val();
            setTeams(data);
          },
          { onlyOnce: true }
        );
      } catch (error) {
        console.log(error);
      }
    };

    getTeams();
    getOrganizerEmails();
  }, [tournamentId]);

  if (!organizerEmails.includes(user.email)) {
    return <NotSignedIn action={"You're not the organizer"} />;
  }

  return (
    <div className="flex flex-col items-center mt-4">
      <SaveSection saveFunction={writeTeams} saveButton={saveButton} />
      <div className="flex flex-col justify-between w-11/12 mb-8 overflow-x-auto">
        <table className="w-11/12">
          <thead className="text-lg">
            <tr>
              <th>{t("page.tournament.registrants.teamName")}</th>
              <th>{t("page.tournament.registrants.players")}</th>
              <th className="w-1/12">
                {t("page.tournament.registrants.email")}
              </th>
              <th>{t("page.tournament.registrants.needsHousing")}</th>
              <th className="w-2/5">
                {t("page.tournament.registrants.housingDetails")}
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.values(teams).map((team, i) => (
              <tr
                key={team.id}
                className={`text-center border rounded ${
                  i % 2 !== 0 && "bg-blue-50"
                }`}
              >
                <td>
                  <input
                    defaultValue={team.teamName}
                    onChange={(event) =>
                      updateTeams(team.id, "teamName", event)
                    }
                    className="bg-transparent	text-center break-all font-bold"
                  />
                </td>
                <td className="flex flex-col">
                  {Object.keys(team).map((key) => {
                    if (!key.includes("player")) return null;
                    return (
                      <>
                        <input
                          key={key}
                          defaultValue={team[key]}
                          onChange={(event) => updateTeams(team.id, key, event)}
                          className="bg-transparent	text-center font-medium"
                        />
                        {team[`club${key.substring(key.length - 1)}`] && (
                          <input
                            key={`club$${key.substring(key.length - 1)}`}
                            defaultValue={
                              team[`club${key.substring(key.length - 1)}`]
                            }
                            onChange={(event) =>
                              updateTeams(
                                team.id,
                                `club${key.substring(key.length - 1)}`,
                                event
                              )
                            }
                            className="bg-transparent	text-center text-sm"
                          />
                        )}
                      </>
                    );
                  })}
                </td>

                <td className="break-all">
                  {team.captainEmail ? team.captainEmail : "❌"}
                </td>
                <td>{team.isTeamHousingRequired ? "✔️" : "❌"}</td>
                <td className="break-all">{team.housingDetails}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Registrants;
