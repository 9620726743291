const generateNextRound = (teamIds, roundNumber) => {
  const matches = [];
  let counter = (roundNumber - 1) * (teamIds.length / 2);

  for (let i = 0; i < teamIds.length; i = i + 2) {
    const match = {
      matchId: `${counter}`,
      team1Id: teamIds[i],
      team1Score: "",
      team2Id: teamIds[i + 1],
      team2Score: "",
      group: roundNumber,
    };
    matches.push(match);
    counter++;
  }

  return matches;
};

export default generateNextRound;
