/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";
import { auth } from "../../firebase/config";
import { signOut } from "firebase/auth";
import { FaDiscord } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Sidebar = ({ sidebarOpen, toggleSidebar, isLoggedIn }) => {
  const { t } = useTranslation();
  const sidebarClass = sidebarOpen ? "translate-x-0" : "-translate-x-full";
  const contactClassname = "w-6 h-6 mx-2 cursor-pointer";

  const logout = async () => {
    try {
      await signOut(auth).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className={`z-40 fixed top-0 ${sidebarClass} w-1/2 max-w-md h-screen bg-sky-800 duration-500`}
      >
        <div
          className="flex flex-col h-full justify-between pt-28 pb-12
          font-mono font-bold text-xl text-center text-white"
        >
          <div className="flex flex-col justify-between h-64">
            <Link to={"/"} onClick={() => toggleSidebar()}>
              {t("component.sidebar.pagelink.home")}
            </Link>
            <Link to={"/organize"} onClick={() => toggleSidebar()}>
              {t("component.sidebar.pagelink.organize")}
            </Link>
            <Link to={"/planner"} onClick={() => toggleSidebar()}>
              {t("component.sidebar.pagelink.planner")}
            </Link>
            <Link to={"/about"} onClick={() => toggleSidebar()}>
              {t("component.sidebar.pagelink.about")}
            </Link>
          </div>
          <section className="flex-col justify-center">
            <>{isLoggedIn && <p onClick={() => logout()}>Logout</p>}</>
            <div className="flex justify-center mt-20">
              <a href="https://discord.gg/ZK9dv3cyvk">
                <FaDiscord className={contactClassname} />
              </a>
              <a href="mailto:shufflepolo@gmail.com">
                <MdOutlineEmail className={contactClassname} />
              </a>
            </div>
          </section>
        </div>
      </div>
      {sidebarOpen && (
        <div
          onClick={() => toggleSidebar()}
          className="z-30 fixed inset-0"
        ></div>
      )}
    </>
  );
};

export default Sidebar;
