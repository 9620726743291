import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { useState } from "react";
import { db } from "../../../../firebase/config";
import { ref, update, set } from "firebase/database";
import { FaRegWindowClose } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Tip from "../../../../components/Tip";

const DeleteBracket = () => {
  const { tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState(false);

  const toggleConfirmation = () => setConfirmation(!confirmation);

  const deleteBracket = async () => {
    const bracketReference = ref(db, `tournaments/${tournamentId}/bracket/`);
    const detailsReference = ref(db, `tournaments/${tournamentId}/details/`);

    try {
      await set(bracketReference, null);
      await update(detailsReference, { isBracketPublic: false });
    } catch (error) {
      console.log(error);
    }

    updateTournament("bracket", null);

    toggleConfirmation();
  };

  return (
    <>
      <div
        onClick={() => toggleConfirmation()}
        className="group absolute top-3 left-0 cursor-pointer mt-[1px]"
      >
        <FaRegWindowClose className="h-6 w-5" />
        <div className="absolute bottom-5 left-[-30px] sm:hidden">
          <Tip text={"Delete Bracket"} />
        </div>
      </div>
      {confirmation && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden inline-block align-middle h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 p-6 pb-4">
                <div className="flex items-start">
                  <div className="mt-3 text-left mt-0 ml-4 text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      {t("page.tournament.bracket.delete.title")}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 whitespace-pre-line mb-2">
                        {t("page.tournament.bracket.delete.firstLine")}
                      </p>
                      <p className="text-sm text-gray-500 whitespace-pre-line">
                        {t("page.tournament.bracket.delete.secondLine")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 px-6 flex flex-row-reverse">
                <button
                  onClick={() => deleteBracket()}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ml-3 w-auto text-sm"
                >
                  {t("misc.confirm")}
                </button>
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-0 w-auto text-sm"
                >
                  {t("misc.cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteBracket;
