const NotSignedIn = ({ action }) => {
  return (
    <div className="flex justify-center">
      <div className="mt-8 text-center">
        <div className="block text-gray-700 text-xl font-bold mb-2">Sorry</div>
        <div className="mt-8">{action}</div>
      </div>
    </div>
  );
};

export default NotSignedIn;
