import updateUpperBracket from "./updateUpperBracket";
import updateLowerBracket from "./updateLowerBracket";
import updateHayesBracket from "./updateHayesBracket";

const updateBracket = (bracketDetails) => {
  updateUpperBracket(bracketDetails);

  if (bracketDetails.lower) {
    updateLowerBracket(bracketDetails);
  }

  if (bracketDetails.hayesUpper) {
    updateHayesBracket(bracketDetails);
  }

  return bracketDetails;
};

export default updateBracket;
