import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { useState } from "react";
import { db } from "../../../../firebase/config";
import { ref, update } from "firebase/database";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";

const OpenRegistration = ({ details }) => {
  const { tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState(false);
  const [registrationInfo, setRegistrationInfo] = useState({
    playersPerTeam: 3,
    isEmailRequired: true,
    isHousingRequired: true,
    isRegistrationOpen: true,
  });

  const toggleConfirmation = () => {
    setConfirmation(!confirmation);
  };

  const writeRegistrationInfo = async (event) => {
    event.preventDefault();
    const registrationReference = ref(db, `registration/${tournamentId}`);
    const tournamentReference = ref(db, `tournaments/${tournamentId}/details`);
    const homeReference = ref(db, `home/${tournamentId}`);

    try {
      await update(registrationReference, registrationInfo);
      await update(tournamentReference, { isRegistrationOpen: true });
      await update(homeReference, { isRegistrationOpen: true });
    } catch (error) {
      console.log(error);
    }

    updateTournament("details", { ...details, isRegistrationOpen: true });

    toggleConfirmation();
  };

  return (
    <div className="mt-4">
      <div onClick={() => toggleConfirmation()}>
        <Button
          i18nPath="page.tournament.details.openRegistration.open"
          color="green"
          hover={true}
        />
      </div>
      {confirmation && (
        <div className="fixed inset-0 overflow-y-auto z-20">
          <div className="flex justify-center min-w-screen pt-40 px-4 pb-20">
            <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
            <form
              onSubmit={(event) => writeRegistrationInfo(event)}
              className="w-11/12 max-w-xl rounded-lg text-left overflow-hidden shadow-xl transform transition-all"
            >
              <div className="bg-white px-8 py-6">
                <h3
                  className="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {t("page.tournament.details.openRegistration.open")}
                </h3>
                <div className="mt-2">
                  <label className="block text-gray-700 text-sm font-semibold mr-3">
                    {t(
                      "page.tournament.details.openRegistration.numberOfPlayers"
                    )}
                  </label>
                  <select
                    onChange={(event) =>
                      setRegistrationInfo((prev) => {
                        return {
                          ...prev,
                          playersPerTeam: Number(event.target.value),
                        };
                      })
                    }
                    className="shadow border rounded py-2 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="dropdown"
                    defaultValue={3}
                  >
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </select>
                </div>
                <div className="mt-2">
                  <p className="leading-6 font-medium text-gray-900">
                    {t("page.tournament.details.openRegistration.requireEmail")}
                  </p>
                  <div className="flex items-center">
                    <span className="font-semibold mr-2">{t("misc.yes")}</span>
                    <label className="flex cursor-pointer">
                      <div
                        className={`relative ${
                          registrationInfo.isEmailRequired
                            ? "bg-green-500"
                            : "bg-red-400"
                        } rounded-full w-12 h-6 transition transition-colors duration-500 ease-in-out`}
                      >
                        <input
                          type="checkbox"
                          className="hidden"
                          checked={registrationInfo.isEmailRequired}
                          onChange={() =>
                            setRegistrationInfo((prev) => {
                              return {
                                ...prev,
                                isEmailRequired:
                                  !registrationInfo.isEmailRequired,
                              };
                            })
                          }
                        />
                        <div
                          className={`absolute left-1 top-1 bg-white w-4 h-4 
                           rounded-full shadow-md transition-transform transform duration-300 ${
                             registrationInfo.isEmailRequired
                               ? "translate-x-full ml-2"
                               : "translate-x-0"
                           }`}
                        ></div>
                      </div>
                    </label>
                    <span className="font-semibold ml-2">{t("misc.no")}</span>
                  </div>
                </div>
                <div className="mt-2">
                  <p className="leading-6 font-medium text-gray-900">
                    {t(
                      "page.tournament.details.openRegistration.requestHousing"
                    )}
                  </p>
                  <p className="text-sm text-gray-500 whitespace-pre-line">
                    {t(
                      "page.tournament.details.openRegistration.requestHousingDetails"
                    )}
                  </p>
                  <div className="flex items-center mt-1">
                    <span className="font-semibold mr-2">{t("misc.yes")}</span>
                    <label className="flex cursor-pointer">
                      <div
                        className={`relative ${
                          registrationInfo.isHousingRequired
                            ? "bg-green-500"
                            : "bg-red-400"
                        } rounded-full w-12 h-6 transition transition-colors duration-500 ease-in-out`}
                      >
                        <input
                          type="checkbox"
                          className="hidden"
                          checked={registrationInfo.isHousingRequired}
                          onChange={() =>
                            setRegistrationInfo((prev) => {
                              return {
                                ...prev,
                                isHousingRequired:
                                  !registrationInfo.isHousingRequired,
                              };
                            })
                          }
                        />
                        <div
                          className={`absolute left-1 top-1 bg-white w-4 h-4 
                           rounded-full shadow-md transition-transform transform duration-300 ${
                             registrationInfo.isHousingRequired
                               ? "translate-x-full ml-2"
                               : "translate-x-0"
                           }`}
                        ></div>
                      </div>
                    </label>
                    <span className="font-semibold ml-2">{t("misc.no")}</span>
                  </div>
                </div>
              </div>
              <div className="flex justify-end w-full bg-gray-50 px-4 py-3">
                <button
                  type="submit"
                  className="rounded-md border border-transparent shadow-sm px-4 py-2 mr-4 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {t("misc.confirm")}
                </button>
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  {t("misc.cancel")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpenRegistration;
