import React, { useState } from "react";
import { useTournament, useTournamentUpdate } from "../../../TournamentContext";
import { db } from "../../../../../firebase/config";
import { ref, update } from "firebase/database";
import Modal from "../../../../../components/modals/Modal";
import MatchTimesInput from "./MatchTimesInput";
import Tip from "../../../../../components/Tip";
import { FaRegClock } from "react-icons/fa";

const MatchTimes = () => {
  const { tournament, tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();

  const details = tournament.details;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startTimes, setStartTimes] = useState(
    details.startTimes ? details.startTimes : []
  );
  const [gameLength, setGameLength] = useState(details.gameLength);
  const [interval, setInterval] = useState(details.interval);
  const [numberOfCourts, setNumberOfCourts] = useState(details.numberOfCourts);

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const handleSetDetails = (detail, value, index) => {
    switch (detail) {
      case "startTimes":
        setStartTimes([
          ...startTimes.slice(0, index),
          value,
          ...startTimes.slice(index + 1),
        ]);
        break;
      case "gameLength":
        setGameLength(Number(value));
        break;
      case "interval":
        setInterval(Number(value));
        break;
      case "numberOfCourts":
        setNumberOfCourts(Number(value));
        break;
      default:
        return;
    }
  };

  const writeMatchTimes = async () => {
    if (!gameLength || !interval || !numberOfCourts) {
      return;
    }

    const detailsRef = ref(db, `tournaments/${tournamentId}/details/`);

    try {
      await update(detailsRef, {
        startTimes: startTimes,
        gameLength: gameLength,
        interval: interval,
        numberOfCourts: numberOfCourts,
      });
    } catch (error) {
      console.log(error);
    }

    updateTournament("details", {
      ...details,
      startTimes: startTimes,
      gameLength: gameLength,
      interval: interval,
      numberOfCourts: numberOfCourts,
    });

    setIsModalVisible(false);
  };

  return (
    <>
      <div
        className="flex group h-8 w-8 border-2 border-black rounded-t-sm
        justify-center items-center cursor-pointer relative"
      >
        <FaRegClock
          onClick={() => setIsModalVisible(true)}
          className="h-6 w-6 fill-black"
        />
        <div className="absolute bottom-8 sm:hidden">
          <Tip text={"Game Time"} />
        </div>
      </div>
      {isModalVisible && (
        <Modal
          i18nTitle={"page.tournament.schedule.gameTimes.title"}
          i18nSubTitle={"page.tournament.schedule.gameTimes.subtitle"}
          ModalBody={
            <div className="flex flex-col py-3">
              <div className="flex flex-wrap mb-4">
                {Object.keys(tournament.schedule).map((day, index) => (
                  <MatchTimesInput
                    key={day}
                    detail={"startTimes"}
                    onChange={handleSetDetails}
                    defaultValue={startTimes[index]}
                    placeholder="min"
                    type={"time"}
                    day={day}
                    index={index}
                  />
                ))}
              </div>
              <div className="flex flex-wrap">
                <MatchTimesInput
                  detail={"gameLength"}
                  i18NextValue={"length"}
                  onChange={handleSetDetails}
                  defaultValue={gameLength}
                  type={"number"}
                  placeholder="min"
                />
                <MatchTimesInput
                  detail={"interval"}
                  i18NextValue={"interval"}
                  onChange={handleSetDetails}
                  defaultValue={interval}
                  type={"number"}
                  placeholder="min"
                />
                <MatchTimesInput
                  detail={"numberOfCourts"}
                  i18NextValue={"courts"}
                  onChange={handleSetDetails}
                  defaultValue={numberOfCourts}
                  type={"number"}
                  placeholder=""
                />
              </div>
            </div>
          }
          onCancel={onCancel}
          onConfirm={writeMatchTimes}
        />
      )}
    </>
  );
};

export default MatchTimes;
