import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useTournament } from "../TournamentContext";
import EditDetails from "./EditDetails";
import OpenRegistration from "./components/OpenRegistration";
import CloseRegistration from "./components/CloseRegistration";
import { BiEdit } from "react-icons/bi";
import AddOrganizer from "./components/AddOrganizer";
import DeleteTournament from "./components/DeleteTournament";
import Button from "../../../components/Button";
import MakeSectionPublic from "../../../components/MakeSectionPublic";

const Details = ({ user }) => {
  const { tournament, tournamentId } = useTournament();

  const details = tournament.details;
  const isUserOrganizer = details.organizerEmails.includes(user.email);

  const [editDetailsState, setEditDetailsState] = useState(false);

  const updateEditDetailsState = () => {
    setEditDetailsState(!editDetailsState);
  };

  return (
    <div className="flex flex-col items-center mb-4">
      {editDetailsState ? (
        <EditDetails updateEditDetailsState={updateEditDetailsState} />
      ) : (
        <>
          {isUserOrganizer && !details.isTournamentPublic && (
            <MakeSectionPublic
              i18nTitle="page.tournament.details.makePublic.title"
              i18nText="page.tournament.details.makePublic.text"
              isSectionPublic="isTournamentPublic"
            />
          )}
          <h2 className="text-2xl font-semibold mb-1">{details.name}</h2>
          <p className="mb-1">{details.location}</p>
          <div className="flex justify-center items-center font-semibold">
            <p className="px-2">{details.startDate}</p>
            {"  -  "}
            <p className="px-2">{details.endDate}</p>
          </div>
          {tournament.details.isRegistrationOpen && (
            <Link to={`/${tournamentId}/register`} className="my-4">
              <Button
                i18nPath="page.tournament.details.register"
                color="blue"
                hover={true}
              />
            </Link>
          )}
          {isUserOrganizer && !!!tournament.schedule && (
            <div className="mb-4">
              {tournament.details.isRegistrationOpen ? (
                <CloseRegistration details={details} />
              ) : (
                <OpenRegistration details={details} />
              )}
            </div>
          )}
          {isUserOrganizer && (
            <>
              <div className="z-20 absolute top-22 left-4 h-10 w-10 cursor-pointer">
                <DeleteTournament
                  tournamentId={tournamentId}
                  imageFileName={details.imageFileName}
                />
                <AddOrganizer details={details} tournamentId={tournamentId} />
              </div>
              <div className="absolute top-22 right-4 h-10 w-10 cursor-pointer p-1 bg-indigo-500 rounded-md shadow-lg">
                <BiEdit
                  onClick={() => updateEditDetailsState()}
                  className={`h-8 w-8 fill-white`}
                />
              </div>
            </>
          )}
          <div className="flex flex-wrap justify-center items-center">
            {details.imageSrc && (
              <img
                src={details.imageSrc}
                className="w-11/12 min-w-[300px] max-w-2xl rounded mb-4"
                alt="tournament-poster"
              />
            )}
            <div className="w-11/12 min-w-[300px] max-w-2xl bg-slate-100 rounded-xl p-4 mb-4 sm:text-center">
              <p className="text-lg text-wrap break-words sm:break-all whitespace-pre-line overflow-hidden">
                {details.description}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Details;
