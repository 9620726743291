import React from "react";
import { useState } from "react";
import { db } from "../../../../firebase/config";
import { ref, set } from "firebase/database";
import { useTranslation } from "react-i18next";
import { MdEditCalendar } from "react-icons/md";
import Tip from "../../../../components/Tip";

const AddDay = ({ schedule, tournamentId, toggleDnd, isSwiss }) => {
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(
    Object.keys(schedule).length
  );

  const toggleDialog = (value) => {
    setConfirmation(value);
    toggleDnd(value);
  };

  const writeSchedule = async () => {
    const newSchedule = {};

    const sortGames = (a, b) => {
      return a.group - b.group;
    };

    const allGames = Object.values(schedule).flat().sort(sortGames);
    const groupedGames = [];

    for (let i = 0; i < allGames.length; i++) {
      if (!groupedGames[allGames[i].group - 1]) {
        groupedGames[allGames[i].group - 1] = [];
      }

      groupedGames[allGames[i].group - 1].push(allGames[i]);
    }

    for (const group of groupedGames) {
      for (let i = numberOfDays; i > 0; i--) {
        if (!!!newSchedule[`day${numberOfDays - i + 1}`]) {
          newSchedule[`day${numberOfDays - i + 1}`] = [];
        }

        const gamesInSameGroup = group.splice(0, Math.ceil(group.length / i));

        newSchedule[`day${numberOfDays - i + 1}`].push(gamesInSameGroup);
      }
    }

    Object.keys(newSchedule).forEach((day) => {
      const flatGames = newSchedule[day].flat();
      newSchedule[day] = flatGames;
    });

    const startTimes = [];

    for (let i = 0; i < numberOfDays; i++) {
      startTimes.push("9:00");
    }

    const scheduleReference = ref(db, `tournaments/${tournamentId}/schedule`);
    const detailsReference = ref(
      db,
      `tournaments/${tournamentId}/details/startTimes`
    );

    try {
      await set(scheduleReference, newSchedule);
      await set(detailsReference, startTimes);
    } catch (error) {
      console.log(error);
    }

    window.location.reload();
  };

  if (isSwiss) {
    return;
  }

  return (
    <>
      <div
        className="flex group h-8 w-8 border-2 border-black rounded-t-sm
        justify-center items-center cursor-pointer relative"
      >
        <MdEditCalendar
          onClick={() => toggleDialog(true)}
          className="h-6 w-6 fill-black"
        />
        <div className="absolute bottom-8 sm:hidden">
          <Tip text={"Add Day"} />
        </div>
      </div>
      {confirmation && (
        <div className="z-20 fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden inline-block align-middle h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white p-5">
                <div className="flex items-start">
                  <div className="mt-3 text-left mt-0 ml-4 text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      {t("page.tournament.schedule.days.title")}
                    </h3>
                    <p className="text-sm text-gray-500 mt-2">
                      {t("page.tournament.schedule.days.label")}
                    </p>
                    <div className="flex items-center mt-1">
                      <select
                        onChange={(event) =>
                          setNumberOfDays(Number(event.target.value))
                        }
                        className="shadow border rounded p-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="dropdown"
                        defaultValue={numberOfDays}
                      >
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 px-6 flex flex-row-reverse">
                <button
                  onClick={() => writeSchedule()}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ml-3 w-auto text-sm"
                >
                  {t("misc.confirm")}
                </button>
                <button
                  onClick={() => toggleDialog(false)}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-0 w-auto text-sm"
                >
                  {t("misc.cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddDay;
