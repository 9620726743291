import React from "react";
import ModalHeader from "./components/ModalHeader";
import ModalFooter from "./components/ModalFooter";

const Modal = ({ i18nTitle, i18nSubTitle, ModalBody, onCancel, onConfirm }) => {
  return (
    <div className="fixed inset-0 overflow-y-auto z-20">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
        <div
          onClick={() => onCancel()}
          className="fixed inset-0 transition-opacity"
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden inline-block align-middle h-screen"></span>
        &#8203;
        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full"
          role="dialog"
          aria-modal="true"
        >
          <ModalHeader i18nTitle={i18nTitle} i18nSubTitle={i18nSubTitle} />
          <div className="flex flex-col items-start px-5">{ModalBody}</div>
          <ModalFooter onCancel={onCancel} onConfirm={onConfirm} />
        </div>
      </div>
    </div>
  );
};

export default Modal;
