import React from "react";
import { useTranslation } from "react-i18next";

const ModalHeader = ({ i18nTitle, i18nSubTitle }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-start bg-white px-5 pt-5 pb-2">
      <h2 className="text-3xl leading-6 font-bold">{t(i18nTitle)}</h2>
      <h5 className="text-lg text-gray-600">{t(i18nSubTitle)}</h5>
    </div>
  );
};

export default ModalHeader;
