import React, { useState } from "react";
import { useTournament } from "../../TournamentContext";
import { db } from "../../../../firebase/config";
import { ref, update } from "firebase/database";
import { useTranslation } from "react-i18next";
import Tip from "../../../../components/Tip";
import Modal from "../../../../components/modals/Modal";
import { PiScalesBold } from "react-icons/pi";

const TiebreakerMatch = ({ schedule, updateSchedule }) => {
  const { tournament, tournamentId } = useTournament();
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [match, setMatch] = useState({
    group: Object.keys(tournament.groups).length + 1,
    matchId:
      tournament.schedule[`day${Object.keys(tournament.schedule).length}`][
        tournament.schedule[`day${Object.keys(tournament.schedule).length}`]
          .length - 1
      ].matchId,
    team1Id: Object.keys(tournament.teams)[0],
    team1Score: "",
    team2Id: Object.keys(tournament.teams)[0],
    team2Score: "",
  });

  const onTeamSelect = (teamNumber, teamId) => {
    setMatch((prev) => {
      return { ...prev, [`team${teamNumber}Id`]: teamId };
    });
  };

  const teamSelect = (teamNumber) => {
    return (
      <select
        onChange={(event) => onTeamSelect(teamNumber, event.target.value)}
      >
        {Object.values(tournament.teams).map((team) => {
          return (
            <option key={team.id} value={team.id}>
              {team.teamName}
            </option>
          );
        })}
      </select>
    );
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const writeTiebreaker = async () => {
    const newSchedule = {
      ...schedule,
      [`day${Object.keys(schedule).length}`]: [
        ...schedule[`day${Object.keys(schedule).length}`],
        match,
      ],
    };

    const scheduleReference = ref(db, `tournaments/${tournamentId}/schedule/`);

    try {
      await update(scheduleReference, newSchedule);
    } catch (error) {
      console.log(error);
    }

    updateSchedule(newSchedule);

    setIsModalVisible(false);
  };

  if (!isModalVisible) {
    return (
      <div
        className="flex group h-8 w-8 border-2 border-black rounded-t-sm
        justify-center items-center cursor-pointer relative"
      >
        <PiScalesBold
          onClick={() => setIsModalVisible(true)}
          className="h-6 w-6"
        />
        <div className="absolute bottom-8 sm:hidden">
          <Tip text={"Tiebreaker"} />
        </div>
      </div>
    );
  }

  return (
    <Modal
      i18nTitle={"page.tournament.schedule.tiebreaker.title"}
      i18nSubTitle={"page.tournament.schedule.tiebreaker.subtitle"}
      ModalBody={
        <div className="flex flex-col w-full">
          <h6 className="text-sm text-gray-500">
            {t("page.tournament.schedule.tiebreaker.detail")}
          </h6>
          <div className="flex w-4/5 justify-around py-4 mt-4">
            {teamSelect(1)}
            {teamSelect(2)}
          </div>
        </div>
      }
      onCancel={onCancel}
      onConfirm={writeTiebreaker}
    />
  );
};

export default TiebreakerMatch;
