import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import ScheduleSortableItem from "./ScheduleSortableItem";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";

const ScheduleContainer = ({
  day,
  matches,
  updateMatch,
  dayIndex,
  isUserOrganizer,
  isDndActivated,
  isSwiss,
  isLastDay,
  deleteSwissRound,
  swapTeams,
}) => {
  const { setNodeRef } = useDroppable({
    day,
  });
  const { t } = useTranslation();

  return (
    <SortableContext
      id={day}
      items={matches.map((match) => match.matchId)}
      strategy={verticalListSortingStrategy}
    >
      <div
        ref={setNodeRef}
        className="flex flex-col items-center w-full max-w-xl rounded-t-xl mx-0.5 my-2 mb-4 relative"
      >
        <div
          className={`font-bold text-white py-1 bg-slate-800 rounded-t-xl w-full text-center`}
        >{`${
          isSwiss
            ? t("page.tournament.swiss.round")
            : t("page.tournament.schedule.day")
        } ${dayIndex + 1}`}</div>
        {isUserOrganizer && isSwiss && isLastDay && dayIndex !== 0 && (
          <IoClose
            onClick={() => deleteSwissRound(dayIndex)}
            className="absolute top-2 right-2 fill-white cursor-pointer"
          />
        )}
        <div className="w-full border">
          {matches.map((match, index) => (
            <ScheduleSortableItem
              key={index}
              match={match}
              matchIndex={index}
              updateMatch={updateMatch}
              dayIndex={dayIndex}
              isUserOrganizer={isUserOrganizer}
              isDndActivated={isDndActivated}
              isOnlyMatch={matches.length === 1}
              isLastMatch={matches.length === index + 1}
              isSwiss={isSwiss}
              swapTeams={swapTeams}
            />
          ))}
        </div>
      </div>
    </SortableContext>
  );
};

export default ScheduleContainer;
