const IconDefinition = ({ icon, label, definition }) => {
  return (
    <div className="flex-col mb-3 ml-2">
      <div className="flex">
        {icon}
        <p className="font-bold">{label}</p>
      </div>
      <p>{definition}</p>
    </div>
  );
};

export default IconDefinition;
