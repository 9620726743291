import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase/config";
import { ref, push, set, onValue } from "firebase/database";
import { MdAddCircle } from "react-icons/md";
import { FaMinusCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";

const Register = ({ user }) => {
  const { tournamentId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [registrationInfo, setRegistrationInfo] = useState({
    isRegistrationOpen: false,
    organizerEmails: [],
  });
  const [clubs, setClubs] = useState([]);
  const [teamDetails, setTeamDetails] = useState({
    wins: 0,
    losses: 0,
    draws: 0,
    delta: 0,
    goals: 0,
    isTeamHousingRequired: false,
    housingDetails: "",
  });
  const [registrationSuccesful, setRegistrationSuccesful] = useState(false);

  useEffect(() => {
    const registrationRef = ref(db, `registration/${tournamentId}/`);
    const clubsRef = ref(db, `/clubs/`);

    const getRegistrationInfo = async () => {
      try {
        onValue(
          registrationRef,
          (snapshot) => {
            const data = snapshot.val();
            setRegistrationInfo(data);
          },
          { onlyOnce: true }
        );

        onValue(
          clubsRef,
          (snapshot) => {
            const data = snapshot.val();
            setClubs(data);
          },
          { onlyOnce: true }
        );
      } catch (error) {
        console.log(error);
      }
    };

    getRegistrationInfo();
  }, [tournamentId]);

  const writeTeam = async (event) => {
    event.preventDefault();

    const reference = ref(db, `tournaments/${tournamentId}/teams/`);
    const addedItem = await push(reference);

    set(addedItem, {
      ...teamDetails,
      id: addedItem.key,
      createdAt: Date.now(),
    })
      .then(() => {
        setRegistrationSuccesful(true);
      })
      .then(() => {
        setTimeout(() => navigate(`/${tournamentId}`), 2000);
      })
      .catch((error) => console.log(error));
  };

  if (!!!user) {
    return (
      <div className="flex justify-center mt-20">
        <p>{t("page.register.mustSignIn")}</p>
      </div>
    );
  }

  if (
    !registrationInfo.isRegistrationOpen &&
    !registrationInfo.organizerEmails.includes(user.email)
  ) {
    return (
      <div className="flex justify-center mt-20">
        <p>{t("page.register.closed")}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center">
      {registrationSuccesful ? (
        <div
          className={`${
            registrationSuccesful
              ? "opacity-100 translate-y-0"
              : "opacity-0 translate-y-4"
          } fixed z-20 top-24 transition-opacity duration-500 ease-in-out transform
            bg-green-500 font-semibold text-white p-4 rounded-md shadow-md`}
        >
          <p>{t("page.register.success")}</p>
        </div>
      ) : (
        <div className="p-8 rounded shadow-lg w-5/6 max-w-lg text-center my-4 bg-white">
          <h2 className="text-2xl font-semibold mb-6">Register</h2>
          <div className="relative mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              {t("page.register.form.teamName")}
            </label>
            <input
              onChange={(event) =>
                setTeamDetails((prev) => {
                  return { ...prev, teamName: event.target.value };
                })
              }
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              required
            />
            <div className="absolute text-red-400 font-bold top-7 right-1">
              *
            </div>
            {teamDetails.teamName?.toLowerCase() === "tbd" && (
              <div className="absolute right-2 top-9">🙁</div>
            )}
          </div>
          {registrationInfo.isEmailRequired && (
            <div className="relative mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("page.register.form.email")}
              </label>
              <input
                onChange={(event) =>
                  setTeamDetails((prev) => {
                    return { ...prev, captainEmail: event.target.value };
                  })
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                required
              />
              <div className="absolute text-red-400 font-bold top-7 right-1">
                *
              </div>
            </div>
          )}
          <div>
            {Array.from(
              Array(
                registrationInfo.playersPerTeam
                  ? registrationInfo.playersPerTeam
                  : 3
              ),
              (e, i) => {
                const playerName = `player${i + 1}`;
                const playerClub = `club${i + 1}`;
                return (
                  <div key={i} className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      {t("page.register.form.player")} {i + 1}
                    </label>
                    <div className="flex flex-wrap justify-center relative">
                      <input
                        onChange={(event) =>
                          setTeamDetails((prev) => {
                            return {
                              ...prev,
                              [playerName]: event.target.value,
                            };
                          })
                        }
                        className="shadow appearance-none border rounded w-full py-2 px-3
                          text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-center mb-1"
                        placeholder="Name"
                        type="text"
                        required
                      />
                      <div className="absolute text-red-400 font-bold top-0 right-1">
                        *
                      </div>
                      {teamDetails[playerName]?.toLowerCase() === "tbd" && (
                        <div className="absolute right-2 top-2">🙁</div>
                      )}
                      <div className="flex justify-between mb-4 w-full">
                        <select
                          onChange={(event) =>
                            setTeamDetails((prev) => {
                              return {
                                ...prev,
                                [playerClub]: event.target.value,
                              };
                            })
                          }
                          id={`${playerClub}-club-list`}
                          className="shadow border rounded py-2 pl-3 w-1/2 mr-1 focus:outline-none"
                          type="dropdown"
                          placeholder="Club"
                          defaultValue={"Home Club"}
                          onClick={() =>
                            (document.getElementById(
                              `${playerClub}-other`
                            ).value = "")
                          }
                        >
                          <option value="Home Club" disabled>
                            {t("page.register.form.otherClub")}
                          </option>
                          {clubs.map((club) => (
                            <option key={club} value={club}>
                              {club}
                            </option>
                          ))}
                          <option>{t("page.register.form.email")}</option>
                        </select>
                        <input
                          onChange={(event) =>
                            setTeamDetails((prev) => {
                              return {
                                ...prev,
                                [playerClub]: event.target.value,
                              };
                            })
                          }
                          onSelect={() =>
                            (document.getElementById(
                              `${playerClub}-club-list`
                            ).value = "Home Club")
                          }
                          id={`${playerClub}-other`}
                          className="shadow appearance-none border rounded py-2 pl-3 ml-1 w-1/2 focus:outline-none"
                          type="text"
                          placeholder="If Other"
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className="flex justify-center items-center mb-5">
            {registrationInfo.playersPerTeam && (
              <>
                <FaMinusCircle
                  onClick={() =>
                    setRegistrationInfo((prev) => {
                      return {
                        ...prev,
                        playersPerTeam:
                          Number(registrationInfo.playersPerTeam) - 1,
                      };
                    })
                  }
                  className="h-8 w-8 cursor-pointer duration-500 fill-red-500 mx-2"
                />
                <MdAddCircle
                  onClick={() =>
                    setRegistrationInfo((prev) => {
                      return {
                        ...prev,
                        playersPerTeam:
                          Number(registrationInfo.playersPerTeam) + 1,
                      };
                    })
                  }
                  className="h-10 w-10 cursor-pointer duration-500 fill-green-500 mx-2"
                />
              </>
            )}
          </div>
          {registrationInfo.isHousingRequired && (
            <div className="relative mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                {t("page.register.form.housingInfo")}
              </label>
              <div className="flex items-center">
                <label className="mr-2">
                  {t("page.register.form.needHousing")}
                </label>
                <input
                  onChange={() =>
                    setTeamDetails((prev) => {
                      return {
                        ...prev,
                        isTeamHousingRequired:
                          !teamDetails.isTeamHousingRequired,
                      };
                    })
                  }
                  checked={teamDetails.isTeamHousingRequired}
                  type="checkbox"
                />
              </div>
              {teamDetails.isTeamHousingRequired && (
                <div className="flex-col">
                  <label className="flex flex-start">
                    {t("page.register.form.additionalInfo")}
                  </label>
                  <textarea
                    onChange={(event) =>
                      setTeamDetails((prev) => {
                        return {
                          ...prev,
                          housingDetails: event.target.value,
                        };
                      })
                    }
                    className="shadow appearance-none border rounded w-full min-h-[200px]
                        py-2 pb-8 px-3 text-gray-700 leading-tight
                        focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Number of People?&#10;Allergies?&#10;Pets?&#10;Anything Else?&#10;"
                    required
                  />
                </div>
              )}
            </div>
          )}
          <div onClick={(event) => writeTeam(event)}>
            <Button
              i18nPath="page.register.form.button"
              color="blue"
              hover={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Register;
