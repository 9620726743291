const Tip = ({ text }) => {
  return (
    <div className="opacity-0 group-hover:opacity-90">
      <div className="w-20 px-1 py-2 rounded bg-black text-white text-center">
        <p className="text-xs font-sm">{text}</p>
      </div>
      <svg height="5" width="80">
        <path
          stroke="black"
          strokeWidth="2"
          fill="black"
          d="M 30 0 L 40 5 L 50 0"
        />
      </svg>
    </div>
  );
};

export default Tip;
