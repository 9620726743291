import React from "react";
import { useTournament } from "../../../TournamentContext";
import { CgSwapVertical } from "react-icons/cg";

const Match = ({
  match,
  updateMatch,
  dayIndex,
  matchIndex,
  isUserOrganizer,
  isDndActivated,
  isLastMatch,
  isSwiss,
  swapTeams,
}) => {
  const { tournament } = useTournament();
  const details = tournament.details;

  const isGameNumberEven = matchIndex % 2 === 0 ? true : false;

  const decideCourt = (matchIndex) => {
    switch (matchIndex % details.numberOfCourts) {
      case 0:
        return "A";
      case 1:
        return "B";
      case 2:
        return "C";
      case 3:
        return "D";
      default:
        return "A";
    }
  };

  const decideMatchBG = (group) => {
    const number = group % 6;
    switch (number) {
      case 1:
        if (isGameNumberEven) {
          return "bg-blue-300";
        } else {
          return "bg-blue-200";
        }
      case 2:
        if (isGameNumberEven) {
          return "bg-red-300";
        } else {
          return "bg-red-200";
        }
      case 3:
        if (isGameNumberEven) {
          return "bg-green-300";
        } else {
          return "bg-green-200";
        }
      case 4:
        if (isGameNumberEven) {
          return "bg-yellow-300";
        } else {
          return "bg-yellow-200";
        }
      case 5:
        if (isGameNumberEven) {
          return "bg-purple-300";
        } else {
          return "bg-purple-200";
        }
      case 0:
        if (isGameNumberEven) {
          return "bg-orange-300";
        } else {
          return "bg-orange-200";
        }
      default:
        return "bg-slate-300";
    }
  };

  const inputClassName = `border w-[35px] h-[35px] text-center font-bold rounded
  [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none
  [&::-webkit-inner-spin-button]:appearance-none`;

  const gameTime = () => {
    if (!details.startTimes[dayIndex]) {
      return;
    }

    const startOClock = details.startTimes
      ? details.startTimes[dayIndex].split(":")
      : "9:00".split(":");

    let hour = Math.floor(
      Number(startOClock[0]) +
        (Math.floor(matchIndex / details.numberOfCourts) *
          (details.gameLength + details.interval)) /
          60
    );

    let minutes =
      Number(startOClock[1]) +
      ((Math.floor(matchIndex / details.numberOfCourts) *
        (details.gameLength + details.interval)) %
        60);

    if (minutes >= 60) {
      minutes = minutes - 60;
      hour = hour + 1;
    }

    return `${hour}:${
      String(minutes).length === 1 ? "0" + String(minutes) : minutes
    }`;
  };

  return (
    <div
      className={`flex flex-col w-full items-center ${decideMatchBG(
        match.group
      )}`}
    >
      {match.referee && (
        <p className="text-xs font-thin max-w-lg truncate -mb-2.5">
          {match.referee}
        </p>
      )}
      <div className="flex justify-center items-center relative h-[50px] w-full">
        <div className="absolute top-0 left-1">
          <p className="text-xs font-thin">{`${matchIndex + 1}`}</p>
        </div>
        <div className="absolute top-0 right-1">
          <p className="text-xs font-thin">{gameTime()}</p>
        </div>
        <div className="flex justify-center items-center text-center w-full">
          <div className="flex w-5/12 justify-end items-center">
            <p className="mr-1 w-3/4 truncate">
              {tournament.teams[match.team1Id].teamName}
            </p>
            {isUserOrganizer && !isDndActivated && !!!tournament.bracket ? (
              <input
                onChange={(event) =>
                  updateMatch(
                    "team1Score",
                    Number(event.target.value),
                    dayIndex,
                    matchIndex
                  )
                }
                defaultValue={match.team1Score}
                className={inputClassName}
                type="number"
              />
            ) : (
              <p className="flex justify-center items-center w-[35px] h-[35px] text-center font-bold">
                {match.team1Score}
              </p>
            )}
          </div>
          <div className="flex flex-col items-center px-2">
            {details.numberOfCourts > 1 && (
              <p className="font-semibold">{decideCourt(matchIndex)}</p>
            )}
          </div>
          <div className="flex w-5/12 justify-start items-center">
            {isUserOrganizer && !isDndActivated && !!!tournament.bracket ? (
              <input
                onChange={(event) =>
                  updateMatch(
                    "team2Score",
                    Number(event.target.value),
                    dayIndex,
                    matchIndex
                  )
                }
                defaultValue={match.team2Score}
                className={inputClassName}
                type="number"
              />
            ) : (
              <p className="flex justify-center items-center w-[35px] h-[35px] text-center font-bold">
                {match.team2Score}
              </p>
            )}
            <p className="ml-1 w-3/4 truncate">
              {tournament.teams[match.team2Id].teamName}
            </p>
          </div>
        </div>
        {isUserOrganizer && isSwiss && !isLastMatch && (
          <CgSwapVertical
            onClick={() => swapTeams(dayIndex, matchIndex)}
            className="z-10 absolute -bottom-2 left-32 cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};

export default Match;
