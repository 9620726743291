import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import PathLines from "./PathLines";

const Matchup = ({
  matchup,
  toggleSaveButton,
  bracketLevel,
  roundNumber,
  matchPosition,
  isOrganizer,
}) => {
  const { tournament } = useTournament();
  const updateTournament = useTournamentUpdate();

  const teams = tournament.teams;
  const bracket = tournament.bracket;

  const updateScore = (teamIdScore, score) => {
    toggleSaveButton(true);
    updateTournament("bracket", {
      ...bracket,
      [bracketLevel]: {
        ...bracket[bracketLevel],
        [`round${roundNumber}`]: [
          ...bracket[bracketLevel][`round${roundNumber}`].slice(
            0,
            matchPosition
          ),
          Object.assign(
            {},
            bracket[bracketLevel][`round${roundNumber}`][matchPosition],
            {
              [teamIdScore]: score,
            }
          ),
          ...bracket[bracketLevel][`round${roundNumber}`].slice(
            matchPosition + 1
          ),
        ],
      },
    });
  };

  const updateTeam = (teamNumberId, newTeamId) => {
    toggleSaveButton(true);

    updateTournament("bracket", {
      ...bracket,
      [bracketLevel]: {
        ...bracket[bracketLevel],
        [`round${roundNumber}`]: [
          ...bracket[bracketLevel][`round${roundNumber}`].slice(
            0,
            matchPosition
          ),
          Object.assign(
            {},
            bracket[bracketLevel][`round${roundNumber}`][matchPosition],
            {
              [teamNumberId]: newTeamId,
            }
          ),
          ...bracket[bracketLevel][`round${roundNumber}`].slice(
            matchPosition + 1
          ),
        ],
      },
    });
  };

  const isDisabled =
    !matchup.team1Id ||
    !matchup.team2Id ||
    matchup.team1Id === "BYE" ||
    matchup.team2Id === "BYE" ||
    matchup.team1Id === "tbd" ||
    matchup.team2Id === "tbd" ||
    matchup.team1Id === "Hayes Winner 1" ||
    matchup.team2Id === "Hayes Winner 1" ||
    matchup.team1Id === "Hayes Winner 2" ||
    matchup.team2Id === "Hayes Winner 2" ||
    !isOrganizer;

  const team1InputBg =
    isDisabled && isOrganizer
      ? "bg-slate-300"
      : matchup.winner === "tbd"
      ? ""
      : matchup.team1Id === matchup.winner
      ? "bg-green-400 font-bold"
      : "bg-blue-100 font-semibold";

  const team2InputBg =
    isDisabled && isOrganizer
      ? "bg-slate-300"
      : matchup.winner === "tbd"
      ? ""
      : matchup.team2Id === matchup.winner
      ? "bg-green-400 font-bold"
      : "bg-blue-100 font-semibold";

  const inputClassName = `flex justify-center items-center w-1/4 max-w-[35px] h-[32px] rounded-r-lg text-center border-l border-slate-600
    [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none
    [&::-webkit-inner-spin-button]:appearance-none`;

  const teamClassName =
    "h-[35px] border border-slate-700 rounded-lg flex justify-between items-center p-1 pr-0 my-0.5 bg-blue-100 shadow-lg";

  const teamName = (teamNumberId) => {
    if (teams[matchup[teamNumberId]]?.teamName) {
      return teams[matchup[teamNumberId]]?.teamName;
    } else if (matchup[teamNumberId] === "tbd") {
      return "";
    } else {
      return matchup[teamNumberId];
    }
  };

  const isTeamChangeEnabled = (teamNumberId) => {
    return (
      isOrganizer &&
      roundNumber === 1 &&
      (bracketLevel === "upper" || bracketLevel === "hayesUpper") &&
      matchup[teamNumberId] !== "BYE"
    );
  };

  if (matchup.team1Id === "BYE" || matchup.team2Id === "BYE") {
    return <div className="h-[88px]"></div>;
  }

  return (
    <div
      className={`flex items-center ${
        matchup.title === "Third Place" &&
        `absolute right-0 ${
          tournament.bracket.upper.round1.length <= 2
            ? "-bottom-12"
            : tournament.bracket.upper.round1.length === 4
            ? "-bottom-4"
            : tournament.bracket.upper.round1.length === 8
            ? "bottom-20"
            : "bottom-1/3"
        }`
      }`}
    >
      {matchup.title !== "Third Place" && (
        <PathLines
          bracketLevel={bracketLevel}
          roundNumber={roundNumber}
          matchPosition={matchPosition}
          bracket={bracket}
        />
      )}
      <div className="w-[200px] m-1.5">
        <div className={`${teamClassName}`}>
          {isTeamChangeEnabled("team1Id") ? (
            <select
              onChange={(event) => updateTeam("team1Id", event.target.value)}
              className="w-3/4 truncate font-semibold appearance-none bg-transparent"
              defaultValue={teamName("team1Id")}
            >
              <option disabled={true}>{teamName("team1Id")}</option>
              {Object.keys(teams).map((teamId) => {
                return <option key={teamId}>{teams[teamId].teamName}</option>;
              })}
            </select>
          ) : (
            <p className="w-3/4 truncate font-semibold">
              {teamName("team1Id")}
            </p>
          )}
          <input
            onChange={(event) =>
              updateScore("team1Score", Number(event.target.value))
            }
            defaultValue={matchup.team1Score}
            className={`${inputClassName} ${team1InputBg}`}
            type="number"
            disabled={isDisabled}
          />
        </div>
        <div className={teamClassName}>
          {isTeamChangeEnabled("team2Id") ? (
            <select
              onChange={(event) => updateTeam("team2Id", event.target.value)}
              className="w-3/4 truncate font-semibold appearance-none bg-transparent"
              defaultValue={teamName("team2Id")}
            >
              <option disabled={true}>{teamName("team2Id")}</option>
              {Object.keys(teams).map((teamId) => {
                return <option key={teamId}>{teams[teamId].teamName}</option>;
              })}
            </select>
          ) : (
            <p className="w-3/4 truncate font-semibold">
              {teamName("team2Id")}
            </p>
          )}
          <input
            onChange={(event) =>
              updateScore("team2Score", Number(event.target.value))
            }
            defaultValue={matchup.team2Score}
            className={`${inputClassName} ${team2InputBg}`}
            type="number"
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default Matchup;
