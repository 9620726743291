import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { ref, set, update } from "firebase/database";
import { db } from "../../../../firebase/config";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import generateSchedule from "./generateSchedule";

const CreateSchedule = ({ updateSchedule }) => {
  const { tournament, tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();
  const { t } = useTranslation();

  const [confirmation, setConfirmation] = useState(false);

  const writeSchedule = async () => {
    const scheduleReference = ref(db, `tournaments/${tournamentId}/schedule`);
    const registrationReference = ref(db, `registration/${tournamentId}`);
    const homeReference = ref(db, `home/${tournamentId}`);
    const detailsReference = ref(db, `tournaments/${tournamentId}/details`);
    const waitlistReference = ref(db, `tournaments/${tournamentId}/waitlist`);

    const schedule = generateSchedule(structuredClone(tournament.groups));

    try {
      await set(scheduleReference, schedule);
      await update(registrationReference, { isRegistrationOpen: false });
      await update(homeReference, { isRegistrationOpen: false });
      await update(detailsReference, { isRegistrationOpen: false });
      await set(waitlistReference, null);
    } catch (error) {
      console.log(error);
    }

    updateSchedule(schedule);

    updateTournament("schedule", schedule);

    setConfirmation(false);
  };

  return (
    <div>
      <div onClick={() => setConfirmation(true)}>
        <Button
          i18nPath="page.tournament.schedule.create.title"
          color="blue"
          hover={true}
        />
      </div>
      {confirmation && (
        <div className="z-20 fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden inline-block align-middle h-screen"></span>
            &#8203;
            <div
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white p-5">
                <div className="flex items-start">
                  <div className="mt-3 text-left mt-0 ml-4 text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      {t("page.tournament.schedule.create.title")}
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 whitespace-pre-line mb-2">
                        {t("page.tournament.schedule.create.firstLine")}
                      </p>
                      <p className="text-sm text-gray-500 whitespace-pre-line">
                        {t("page.tournament.schedule.create.secondLine")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 px-6 flex flex-row-reverse">
                <button
                  onClick={() => writeSchedule()}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ml-3 w-auto text-sm"
                >
                  {t("misc.confirm")}
                </button>
                <button
                  onClick={() => setConfirmation(false)}
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-0 w-auto text-sm"
                >
                  {t("misc.cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateSchedule;
