import generateUpperBracket from "./generateUpperBracket";
import generateLowerBracket from "./generateLowerBracket";

const generateBracket = (
  groups,
  teams,
  bracketType,
  numberOfTeams,
  numberOfHayesTeams
) => {
  const bracket = { upper: {} };
  const sortedTeams = [];

  const sortSameRankedTeams = (a, b) => {
    if (Object.keys(groups).length <= 2) {
      return 1;
    }

    return (
      teams[b]?.wins - teams[a]?.wins ||
      teams[b]?.draws - teams[a]?.draws ||
      teams[a]?.losses - teams[b]?.losses ||
      teams[b]?.delta - teams[a]?.delta ||
      teams[b]?.goals - teams[a]?.goals
    );
  };

  const longestGroupLength = (groups) => {
    let longestGroup = 0;
    Object.values(groups).forEach((group) => {
      if (group.length > longestGroup) longestGroup = group.length;
    });
    return longestGroup;
  };

  for (let i = 0; i < longestGroupLength(groups); i++) {
    const sameRankedTeams = [];

    for (const group of Object.values(groups)) {
      if (!!group[i]) {
        sameRankedTeams.push(group[i]);
      } else {
        sameRankedTeams.push("BYE");
      }
    }

    sameRankedTeams.sort(sortSameRankedTeams);

    for (const team of sameRankedTeams) {
      sortedTeams.push(team);
    }
  }

  if (bracketType === "single") {
    const numberOfRounds = Math.ceil(Math.log(numberOfTeams) / Math.log(2));

    bracket["upper"] = generateUpperBracket(sortedTeams, numberOfRounds);
  }

  if (bracketType === "double") {
    const numberOfRounds = Math.ceil(Math.log(numberOfTeams) / Math.log(2));

    bracket["upper"] = generateUpperBracket(sortedTeams, numberOfRounds);

    bracket.upper[`round${Object.keys(bracket.upper).length + 1}`] = [
      {
        team1Id: "tbd",
        team2Id: "tbd",
        team1Score: "",
        team2Score: "",
        winner: "tbd",
        loser: "tbd",
      },
    ];

    bracket.upper[`round${Object.keys(bracket.upper).length + 1}`] = [
      {
        team1Id: "tbd",
        team2Id: "tbd",
        team1Score: "",
        team2Score: "",
        winner: "tbd",
        loser: "tbd",
      },
    ];

    bracket["lower"] = generateLowerBracket(numberOfRounds);
  }

  if (bracketType.includes("hayes")) {
    const numberOfTeamsToExclude = bracketType === "hayesSingle" ? 1 : 2;

    const topHayesTeams = sortedTeams.splice(
      0,
      numberOfHayesTeams - numberOfTeamsToExclude
    );

    const bottomHayesTeams = sortedTeams.splice(
      0,
      numberOfTeams - numberOfHayesTeams + numberOfTeamsToExclude
    );

    bracketType === "hayesSingle"
      ? topHayesTeams.push("Hayes Winner 1")
      : topHayesTeams.push("Hayes Winner 1", "Hayes Winner 2");

    const numberOfRounds = Math.ceil(
      Math.log(topHayesTeams.length) / Math.log(2)
    );

    const numberOfHayesRounds = Math.ceil(
      Math.log(bottomHayesTeams.length) / Math.log(2)
    );

    bracket["upper"] = generateUpperBracket(topHayesTeams, numberOfRounds);

    bracket["hayesUpper"] = generateUpperBracket(
      bottomHayesTeams,
      numberOfHayesRounds
    );

    if (bracketType === "hayesDouble") {
      bracket.upper[`round${Object.keys(bracket.upper).length + 1}`] = [
        {
          team1Id: "tbd",
          team2Id: "tbd",
          team1Score: "",
          team2Score: "",
          winner: "tbd",
          loser: "tbd",
        },
      ];

      bracket.upper[`round${Object.keys(bracket.upper).length + 1}`] = [
        {
          team1Id: "tbd",
          team2Id: "tbd",
          team1Score: "",
          team2Score: "",
          winner: "tbd",
          loser: "tbd",
        },
      ];

      bracket["lower"] = generateLowerBracket(numberOfRounds);

      bracket["hayesLower"] = generateLowerBracket(numberOfHayesRounds);
    }
  }

  if (bracketType.toLowerCase().includes("single")) {
    bracket.upper[`round${Object.keys(bracket.upper).length}`].push({
      team1Id: "tbd",
      team2Id: "tbd",
      team1Score: "",
      team2Score: "",
      winner: "tbd",
      loser: "tbd",
      title: "Third Place",
    });
  }

  return bracket;
};

export default generateBracket;
