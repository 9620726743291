import React from "react";

const Up = ({ svgHeight }) => {
  return (
    <svg className="absolute" width="20" height={`${svgHeight}`}>
      <path
        stroke="black"
        strokeWidth="2"
        fill="none"
        d={`M 0 0 L 10 0 L 10 ${svgHeight / 2}  L 20 ${svgHeight / 2}`}
      />
    </svg>
  );
};

export default Up;
