import React from "react";

const Down = ({ svgHeight }) => {
  return (
    <svg className="absolute" width="20" height={`${svgHeight}`}>
      <path
        stroke="black"
        strokeWidth="2"
        fill="none"
        d={`M 0 ${svgHeight} L 10 ${svgHeight} L 10 ${svgHeight / 2} L 20 ${
          svgHeight / 2
        }`}
      />
    </svg>
  );
};

export default Down;
