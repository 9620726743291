import { Link } from "react-router-dom";
import { useState } from "react";
import { useTournament } from "../TournamentContext";
import { ref, set } from "firebase/database";
import { db } from "../../../firebase/config";
import { IoAddCircleOutline } from "react-icons/io5";
import { TbListDetails } from "react-icons/tb";
import MakeTeamsPublic from "./components/MakeTeamsPublic";
import TeamsCard from "./components/TeamsCard";
import AddTeams from "./components/AddTeams";
import SaveSection from "../components/SaveSection";
import { useTranslation } from "react-i18next";
import Tip from "../../../components/Tip";

const Teams = ({ user }) => {
  const { tournament, tournamentId } = useTournament();
  const { t } = useTranslation();

  const [saveButton, setSaveButton] = useState(false);

  const toggleSaveButton = (value) => {
    setSaveButton(value);
  };

  const teams = tournament.teams;
  const details = tournament.details;
  const waitlist = tournament.waitlist;
  const isUserOrganizer = details.organizerEmails.includes(user.email);

  const writeTeams = async () => {
    const teamsReference = ref(db, `tournaments/${tournamentId}/teams/`);
    const waitlistReference = ref(db, `tournaments/${tournamentId}/waitlist/`);

    try {
      await set(teamsReference, tournament.teams);
      await set(waitlistReference, tournament.waitlist);
    } catch (error) {
      console.log(error);
    }

    toggleSaveButton(false);
  };

  return (
    <>
      {details.isTeamsPublic || (isUserOrganizer && !details.isTeamsPublic) ? (
        <div className="flex flex-col items-center w-full mb-6">
          <SaveSection saveFunction={writeTeams} saveButton={saveButton} />
          <div
            className="relative flex justify-center w-full text-center
                  border-b-2 border-slate-600 mb-2 relative"
          >
            {isUserOrganizer && !!!tournament.schedule && (
              <AddTeams teams={teams} />
            )}
            <p className="font-bold text-2xl">{`${t(
              "page.tournament.teams.teams"
            )} ${details.isTeamsPublic ? "" : t("misc.notPublic")}`}</p>
            {isUserOrganizer && !!teams && (
              <Link
                to={`/${tournamentId}/registrants`}
                className="group absolute top-2 right-0 text-ellipsis"
              >
                <div className="absolute bottom-6 left-[-28px] sm:hidden">
                  <Tip text={"View Team Details"} />
                </div>
                <TbListDetails className="w-6 h-6" />
              </Link>
            )}
          </div>
          {isUserOrganizer && !details.isTeamsPublic && (
            <MakeTeamsPublic details={details} />
          )}
          {!!teams && (
            <div className="flex flex-wrap justify-center w-full mb-8">
              {Object.values(teams).map((team) => (
                <TeamsCard
                  key={team.id}
                  team={team}
                  isUserOrganizer={isUserOrganizer}
                  toggleSaveButton={toggleSaveButton}
                  isWaitlist={false}
                />
              ))}
            </div>
          )}
          {isUserOrganizer && !!!tournament.schedule && (
            <Link to={`/${tournamentId}/register`}>
              <IoAddCircleOutline className="h-10 w-10 mb-4 cursor-pointer" />
            </Link>
          )}
          {!!waitlist && (
            <>
              <div
                className="flex justify-center w-full text-center
                border-b-2 border-slate-600 mb-2 relative"
              >
                <p className="font-bold text-2xl">{`${t(
                  "page.tournament.waitlist.waitlist"
                )}`}</p>
              </div>
              <div className="flex flex-wrap justify-center w-full mb-8">
                {Object.values(waitlist).map((team) => (
                  <TeamsCard
                    key={team.id}
                    team={team}
                    isUserOrganizer={isUserOrganizer}
                    toggleSaveButton={toggleSaveButton}
                    isWaitlist={true}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Teams;
