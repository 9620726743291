const updateLowerBracket = (bracketDetails) => {
  for (let i = 0; i < bracketDetails.lower.round1.length * 2; i += 2) {
    const matchup = bracketDetails.lower.round1[Math.round(i / 2)];

    matchup.team1Id = bracketDetails.upper.round1[i].loser;

    matchup.team2Id = bracketDetails.upper.round1[i + 1].loser;

    if (matchup.team1Score > matchup.team2Score) {
      matchup["winner"] = matchup.team1Id;
      matchup["loser"] = matchup.team2Id;
    } else if (matchup.team2Score > matchup.team1Score) {
      matchup["winner"] = matchup.team2Id;
      matchup["loser"] = matchup.team1Id;
    } else if (matchup.team1Id === "BYE") {
      matchup["winner"] = matchup.team2Id;
      matchup["loser"] = matchup.team1Id;
    } else if (matchup.team2Id === "BYE") {
      matchup["winner"] = matchup.team1Id;
      matchup["loser"] = matchup.team2Id;
    }
  }

  for (
    let round = 2;
    round <= Object.keys(bracketDetails.lower).length;
    round++
  ) {
    if (round % 2 === 0) {
      for (let j = 0; j < bracketDetails.lower[`round${round}`].length; j++) {
        const matchup = bracketDetails.lower[`round${round}`][j];

        if (round % 4 === 0) {
          matchup.team1Id =
            bracketDetails.upper[`round${round - (round / 2 - 1)}`][j].loser;
        } else {
          matchup.team1Id =
            bracketDetails.upper[`round${round - (round / 2 - 1)}`][
              bracketDetails.upper[`round${round - (round / 2 - 1)}`].length -
                1 -
                j
            ].loser;
        }

        matchup.team2Id = bracketDetails.lower[`round${round - 1}`][j].winner;

        if (matchup.team1Score > matchup.team2Score) {
          matchup["winner"] = matchup.team1Id;
          matchup["loser"] = matchup.team2Id;
        } else if (matchup.team2Score > matchup.team1Score) {
          matchup["winner"] = matchup.team2Id;
          matchup["loser"] = matchup.team1Id;
        } else if (matchup.team1Id === "BYE") {
          matchup["winner"] = matchup.team2Id;
          matchup["loser"] = matchup.team1Id;
        } else if (matchup.team2Id === "BYE") {
          matchup["winner"] = matchup.team1Id;
          matchup["loser"] = matchup.team2Id;
        }
      }
    } else {
      for (
        let j = 0;
        j < bracketDetails.lower[`round${round}`].length * 2;
        j += 2
      ) {
        const matchup =
          bracketDetails.lower[`round${round}`][Math.round(j / 2)];

        matchup.team1Id = bracketDetails.lower[`round${round - 1}`][j].winner;
        matchup.team2Id =
          bracketDetails.lower[`round${round - 1}`][j + 1].winner;

        if (matchup.team1Score > matchup.team2Score) {
          matchup["winner"] = matchup.team1Id;
          matchup["loser"] = matchup.team2Id;
        } else if (matchup.team2Score > matchup.team1Score) {
          matchup["winner"] = matchup.team2Id;
          matchup["loser"] = matchup.team1Id;
        } else if (matchup.team1Id === "BYE") {
          matchup["winner"] = matchup.team2Id;
          matchup["loser"] = matchup.team1Id;
        } else if (matchup.team2Id === "BYE") {
          matchup["winner"] = matchup.team1Id;
          matchup["loser"] = matchup.team2Id;
        }
      }
    }
  }

  const grandFinalsMatchup =
    bracketDetails.upper[
      `round${Object.keys(bracketDetails.upper).length - 1}`
    ][0];

  grandFinalsMatchup.team1Id =
    bracketDetails.upper[
      `round${Object.keys(bracketDetails.upper).length - 2}`
    ][0].winner;

  grandFinalsMatchup.team2Id =
    bracketDetails.lower[
      `round${Object.keys(bracketDetails.lower).length}`
    ][0].winner;

  if (grandFinalsMatchup.team1Score > grandFinalsMatchup.team2Score) {
    grandFinalsMatchup["winner"] = grandFinalsMatchup.team1Id;
    grandFinalsMatchup["loser"] = grandFinalsMatchup.team2Id;
  } else if (grandFinalsMatchup.team2Score > grandFinalsMatchup.team1Score) {
    grandFinalsMatchup["winner"] = grandFinalsMatchup.team2Id;
    grandFinalsMatchup["loser"] = grandFinalsMatchup.team1Id;
  }

  if (grandFinalsMatchup.team2Score > grandFinalsMatchup.team1Score) {
    const grandFinalsReset =
      bracketDetails.upper[
        `round${Object.keys(bracketDetails.upper).length}`
      ][0];

    grandFinalsReset.team1Id = grandFinalsMatchup.team1Id;
    grandFinalsReset.team2Id = grandFinalsMatchup.team2Id;

    if (grandFinalsReset.team1Score > grandFinalsReset.team2Score) {
      grandFinalsReset["winner"] = grandFinalsReset.team1Id;
      grandFinalsReset["loser"] = grandFinalsReset.team2Id;
    } else if (grandFinalsReset.team2Score > grandFinalsReset.team1Score) {
      grandFinalsReset["winner"] = grandFinalsReset.team2Id;
      grandFinalsReset["loser"] = grandFinalsReset.team1Id;
    }
  }
};

export default updateLowerBracket;
