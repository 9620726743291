// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// THIS IS THE REAL firebaseConfig FOR PROD
// DO NOT UNCOMMENT UNLESS NECESSARY

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyASMynKedXwDHS3juH5Uw_qnSG1SgDWA6Y",
  authDomain: "shuffler-5e5dd.firebaseapp.com",
  projectId: "shuffler-5e5dd",
  storageBucket: "shuffler-5e5dd.appspot.com",
  messagingSenderId: "20345331975",
  appId: "1:20345331975:web:7e96b091ccce4f777565a2",
  measurementId: "G-9NR6QNXGB8",
};

// This is the DEV config
// const firebaseConfig = {
//   apiKey: "AIzaSyAXpws8UgD8aJ01WHLyCpkr2WjVfHa5J9w",
//   authDomain: "shufflerdev.firebaseapp.com",
//   databaseURL: "https://shufflerdev-default-rtdb.firebaseio.com",
//   projectId: "shufflerdev",
//   storageBucket: "shufflerdev.appspot.com",
//   messagingSenderId: "1043743315800",
//   appId: "1:1043743315800:web:7263d48c430a366aa1372d",
//   measurementId: "G-7YXFP19CNF",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getDatabase(app);
export const storage = getStorage(app);
export const firestore = getFirestore(app);
