import { useTournament, useTournamentUpdate } from "../TournamentContext";
import { db } from "../../../firebase/config";
import { ref, update } from "firebase/database";
import { BiEdit } from "react-icons/bi";
import { useState } from "react";
import SaveSection from "../components/SaveSection";

const EditDetails = ({ updateEditDetailsState }) => {
  const { tournament, tournamentId } = useTournament();
  const updateTournament = useTournamentUpdate();

  const [saveButton, setSaveButton] = useState(false);

  const details = tournament.details;

  const updateSaveButton = () => {
    if (saveButton === true) {
      return;
    }

    setSaveButton(true);
  };

  const updateDetails = async () => {
    const detailsReference = ref(db, `tournaments/${tournamentId}/details`);
    const homeReference = ref(db, `home/${tournamentId}`);

    try {
      await update(detailsReference, details);
      await update(homeReference, details);
    } catch (error) {
      console.log(error);
    }

    updateEditDetailsState();
  };

  return (
    <>
      {tournament && (
        <>
          <div className="flex justify-between w-full">
            <div />
            <div className="flex flex-col items-center w-5/6 text-center mb-8">
              <input
                className="w-5/6 max-w-xl text-2xl border rounded-lg font-semibold mb-1"
                defaultValue={details.name || ""}
                onChange={(event) => {
                  updateTournament("details", {
                    ...details,
                    name: event.target.value,
                  });
                  updateSaveButton();
                }}
              />
              <input
                className="w-5/6 max-w-xl font-semibold border rounded-lg mb-1"
                defaultValue={details.location || ""}
                onChange={(event) => {
                  updateTournament("details", {
                    ...details,
                    location: event.target.value,
                  });
                  updateSaveButton();
                }}
              />
              <div className="flex justify-center items-center font-semibold">
                <input
                  defaultValue={details.startDate || ""}
                  type={"date"}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-center"
                  onChange={(event) => {
                    updateTournament("details", {
                      ...details,
                      startDate: new Date(event.target.value).toDateString(),
                    });
                    updateSaveButton();
                  }}
                  // min={new Date().toISOString().split("T")[0]}
                  max={new Date(details.endDate).toISOString().split("T")[0]}
                />
                {"  -  "}
                <input
                  defaultValue={details.endDate || ""}
                  placeholder="Start Date"
                  type={"date"}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-center"
                  onChange={(event) => {
                    updateTournament("details", {
                      ...details,
                      endDate: new Date(event.target.value).toDateString(),
                    });
                    updateSaveButton();
                  }}
                  min={new Date(details.startDate).toISOString().split("T")[0]}
                />
              </div>
            </div>
            <div className="h-10 w-10 cursor-pointer p-1 bg-indigo-500 rounded-md shadow-lg">
              <BiEdit
                onClick={() => updateEditDetailsState()}
                className={`h-8 w-8 fill-slate-300`}
              />
            </div>
          </div>
          <div className="flex flex-wrap justify-around w-5/6 mb-4">
            <div className="flex justify-center items-center">
              <img
                src={details.imageSrc}
                className="w-5/6 min-w-[300px] max-w-xl mb-6"
                alt="tournament-post-edit"
              />
            </div>
            <textarea
              defaultValue={details.description || ""}
              className="break-words text-lg text-center border 
              w-5/6 max-w-xl min-h-[200px] whitespace-pre-line"
              onChange={(event) => {
                updateTournament("details", {
                  ...details,
                  description: event.target.value,
                });
                updateSaveButton();
              }}
            />
          </div>
          <SaveSection saveFunction={updateDetails} saveButton={saveButton} />
        </>
      )}
    </>
  );
};

export default EditDetails;
