import { useTournament, useTournamentUpdate } from "../../TournamentContext";
import { IoClose } from "react-icons/io5";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";

const TeamsCard = ({
  team,
  isUserOrganizer,
  toggleSaveButton,
  saveButton,
  isWaitlist,
}) => {
  const { tournament } = useTournament();
  const updateTournament = useTournamentUpdate();

  const teams = structuredClone(tournament.teams || {});
  const waitlist = structuredClone(tournament.waitlist || {});

  const deleteTeam = async () => {
    if (isWaitlist) {
      delete waitlist[team.id];

      updateTournament("waitlist", waitlist);
    } else {
      delete teams[team.id];

      updateTournament("teams", teams);
    }

    if (!saveButton) {
      toggleSaveButton(true);
    }
  };

  const moveTeam = () => {
    if (isWaitlist) {
      teams[team.id] = team;
    } else {
      waitlist[team.id] = team;
    }

    deleteTeam();

    updateTournament("waitlist", waitlist);

    updateTournament("teams", teams);

    if (!saveButton) {
      toggleSaveButton(true);
    }
  };

  const registeredAt = (timestamp) => {
    const createdAt = new Date(timestamp);
    const month = createdAt.getMonth();
    const date = createdAt.getDate();
    const hours = createdAt.getHours();
    const minutes = createdAt.getMinutes();
    const seconds = createdAt.getSeconds();

    return `${date}/${month + 1} - ${hours}:${minutes}:${seconds}`;
  };

  if (team.id === "bye") {
    return;
  }

  return (
    <div className="w-[300px] border rounded-lg shadow-md m-2 mb-2">
      <div
        className={`flex justify-center items-center bg-blue-900 w-full rounded-t-lg p-1 relative`}
      >
        {isUserOrganizer && !!!tournament.schedule && (
          <>
            {isWaitlist ? (
              <FaArrowUp
                onClick={() => moveTeam()}
                className="absolute top-2 left-1 h-3 w-3 cursor-pointer text-white"
              />
            ) : (
              <FaArrowDown
                onClick={() => moveTeam()}
                className="absolute top-2 left-1 h-3 w-3 cursor-pointer text-white"
              />
            )}
            <IoClose
              onClick={() => {
                deleteTeam();
              }}
              className="absolute top-1 right-1 h-4 w-4 cursor-pointer text-white"
            />
          </>
        )}
        <p className="font-bold text-center text-white text-wrap break-all w-5/6">
          {team.teamName}
        </p>
      </div>
      {isUserOrganizer && team.createdAt && (
        <p className="w-11/12 text-center text-xs font-thin">{`${registeredAt(
          team.createdAt
        )}`}</p>
      )}
      <div className={`rounded-b-lg py-1 px-2`}>
        {Object.keys(team).map((key) => {
          if (!key.includes("player") || !team[key]) return null;
          return (
            <div key={key} className="flex flex-wrap justify-between">
              <p className="break-all">{team[key]}</p>
              {team[`club${key.substring(key.length - 1)}`] && (
                <p className="break-all">
                  {team[`club${key.substring(key.length - 1)}`]}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TeamsCard;
